import React from 'react';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import MasterPage from '../../../masterpages/authenticated';
import ContentBox from '../../../../components/ContentBox';
import Form from '../../../assets/formValidate';
import Select from '../../../components/form/SelectGroup';
import Input from '../../../components/form/InputGroup';
import Modal from '../../../components/modal/modal';
import generateEmail from '../../../actions/contacts/generateEmail';
import Mask from 'utils/masks';
import checkUpnAvailability from '../../../actions/validation/checkUpnAvailability';
import EmailsService from 'services/EmailsService';
import CheckBox from "../../../components/form/CheckBox";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setLoadingItem, removeLoadingItem } from '../../../components/loading/loadingActions';
import {
	rdxClearUsers,
	rdxGetUserFields,
	rdxGetPerfis,
	rdxGetLinhaServico,
	rdxGetPlansRightsByEntitlementId,
	rdxGetAdditionalProductsRightByEntitlementId,
	rdxGetAccountById
} from '../redux/usuariosActions';
import { NotificationManager } from 'react-notifications';
import ValidateCod from '../componentes/ValidarCodigoAssessor';
import CustomSelect from '../../../components/customSelect/customSelect';
import { FormHeader } from '../componentes/FormHeader/index';
import { checkBundleLimit } from '../../../actions/validation/checkBundleLimit';
import Timeline from '../../../components/timeline';
import Concluded from '../componentes/concluded';
import Confirmation from '../componentes/confirmation';
import NavigationButtons from '../componentes/navigationButtons';
import policiesObject from '../../../pages/Provisionamento/polices.json';
import {
	ContentWrapperStyle
} from './styles';

import {
	PerfisDeAcesso
} from '../componentes/styles';

import { profileNavGerenciamentoUsuarios, profilesV2 } from 'EstruturaAntiga/permissions/permissionsV2';
import TemAcessoAosProfiles from 'EstruturaAntiga/permissions/checkPermissionV2';
import { isTenantNCE } from '../../../router/routerHelpers';
import CONSTANTES_AMBIENTE from 'constants/constantesAmbiente';
import { getFormId, getLabelCheckboxAssessor } from '../componentes/Uteis';
import verifyConsultantCode from 'EstruturaAntiga/actions/usuarios/verifyConsultantCode';
import GetDireitoPorId from 'EstruturaAntiga/actions/direitos/getDireitoPorId';
import ErroGestaoUsuario from '../ErroGestaoUsuario';
import ModalGestaoUsuarioResponse from 'EstruturaAntiga/pages/ModalGestaoUsuarioResponse';
import LoadingShadow from 'EstruturaAntiga/components/loadingShadow';
import UsuariosService from 'services/UsuariosService';
import AssessoresService from 'services/AssessoresService';
import DominiosService from 'services/DominiosService';
import { Tooltip } from 'antd';

class CriarUsuariosV2 extends Form {
	constructor() {
		super()

		this.state = {
			...this.state,
			erroPerfisDeAcesso: false,
			timelinePosition: 0,
			accepted: true,
			checkedPolicy: false,
			isSubmit: false,
			formHeaders: {
				formName: '',
				tenant: '',
				formTitle: ''
			},
			sufixList: [],
			sufixLoading: true,
			loadingForm: false,
			loadingAutorizacao: false,
			autorizado: true,
			listaPerfis: [],
			controlAdvisorCod: true,
			messageAdvisorCod: '',
			advisorCodIsLoading: '',
			modalOpen: '',
			renderizarPlanodeEmail: false,
			codassessor: '',
			perfil: '',
			listaProdutoComplementoExibicao: [],
			LicensePlanExibicao: '',
			tipoUsuario: '',
			create: false,
			direito: [],
			perfis: [],
			oferta: [],
			profiles: [],
			priceLevelCurrencySymbol: '',
			priceLevelAmount: 0,
			priceLevelCurrencyName: '',
			addonCurrencySymbol: '',
			addonAmount: 0,
			addonCurrencyName: '',
			usuario: '',
			showPerfisPortal: false,
			erroIncluirPerfil: '',
			searchUpn: false,
			searchEmail: false,
			searchAlternateEmail: false,
			searchBundleLimit: false,
			hasAcessor: false,
			focusExtensionAttribute8: false,
			criarUsuarioResponse: [],
			criarUsuarioErro: false,
			showLoadingShadow: false,
			operationUserLog: {
				modalContent: ""
			},
			formContent: {
				axt_onpremisessyncenabled: {
					nome: 'Nuvem',
					somenteLeitura: true,
					visible: false,
					obrigatorio: false,
					tamanho: null
				},
				adx_organizationname: {
					nome: 'Empresa',
					somenteLeitura: true,
					visible: false,
					obrigatorio: false,
					tamanho: null
				},
				firstname: {
					nome: 'Nome',
					somenteLeitura: true,
					visible: false,
					obrigatorio: false,
					tamanho: null
				},
				lastname: {
					nome: 'Sobrenome',
					somenteLeitura: true,
					visible: false,
					obrigatorio: false,
					tamanho: null
				},
				axt_displayname365: {
					nome: "Nome de exibição",
					somenteLeitura: false,
					visible: false,
					obrigatorio: false,
					tamanho: null,
				},
				mobilephone: {
					nome: 'Celular',
					somenteLeitura: true,
					visible: false,
					obrigatorio: false,
					tamanho: null,
					mask: 'phone'
				},
				telephone1: {
					nome: 'Telefone',
					somenteLeitura: true,
					visible: false,
					obrigatorio: false,
					tamanho: null,
					mask: 'phone'
				},
				axt_extensionattribute1: {
					nome: 'Tipo de usuário',
					somenteLeitura: true,
					visible: false,
					obrigatorio: false,
					tamanho: null
				},
				axt_extensionattribute2: {},
				axt_extensionattribute3: {
					nome: 'Razão Social',
					somenteLeitura: true,
					visible: false,
					obrigatorio: false,
					tamanho: null
				},
				axt_extensionattribute4: {
					nome: 'CNPJ',
					somenteLeitura: true,
					visible: false,
					obrigatorio: false,
					tamanho: null
				},
				axt_extensionattribute5: {
					nome: 'Código do Assessor',
					somenteLeitura: true,
					visible: false,
					obrigatorio: false,
					tamanho: null
				},
				axt_extensionattribute6: {},
				axt_extensionattribute7: {},
				axt_extensionattribute8: {
					nome: 'E-mail alternativo',
					somenteLeitura: true,
					visible: false,
					obrigatorio: false,
					tamanho: null
				},
				axt_extensionattribute9: {},
				axt_extensionattribute10: {},
				axt_extensionattribute11: {},
				axt_extensionattribute12: {},
				axt_extensionattribute13: {},
				axt_extensionattribute14: {},
				axt_extensionattribute15: {},
				emailaddress2: {
					nome: 'E-mail alternativo',
					somenteLeitura: true,
					visible: false,
					obrigatorio: false,
					tamanho: null
				},
				department: {
					nome: 'Departamento',
					somenteLeitura: true,
					visible: false,
					obrigatorio: false,
					tamanho: null
				},
				jobtitle: {
					nome: 'Cargo',
					somenteLeitura: true,
					visible: false,
					obrigatorio: false,
					tamanho: null
				},
				_axt_servicebundle_value: {
					nome: 'Plano de E-mail',
					somenteLeitura: true,
					visible: false,
					obrigatorio: false,
					tamanho: null,
					lista: []
				},
				listProductAddons: {
					nome: 'Produtos Opcionais',
					somenteLeitura: true,
					visible: false,
					obrigatorio: false,
					tamanho: null,
					lista: []
				},
				axt_userprincipalname: {
					nome: 'Login',
					somenteLeitura: true,
					visible: false,
					obrigatorio: false,
					tamanho: null
				},
				SufixMail: {
					nome: 'Domínio',
					somenteLeitura: true,
					visible: false,
					obrigatorio: false,
					tamanho: null
				},
				emailaddress1: {
					nome: 'E-mail',
					somenteLeitura: true,
					visible: false,
					obrigatorio: false,
					tamanho: null
				},
				HabilitarMFA: {
					nome: 'Habilitar MFA',
					somenteLeitura: false,
					visible: true,
					obrigatorio: false,
					tamanho: null,
					lista: []
				},
				emailVisualization: {
					atributo: "emailVisualization",
					formName: null,
					id: 'emailVisualization',
					lista: [],
					nome: "E-mail",
					obrigatorio: false,
					somenteLeitura: true,
					tamanho: 150,
					valores: [],
					visible: true
				}
			},
			form: {
				UsuarioAcessor: true,
				axt_onpremisessyncenabled: '',
				adx_organizationname: '',
				axt_extensionattribute1: '',
				axt_extensionattribute3: '',
				axt_extensionattribute4: '',
				axt_extensionattribute5: '',
				axt_extensionattribute8: '',
				emailaddress2: '',
				firstname: '',
				lastname: '',
				axt_displayname365: '',
				mobilephone: '',
				telephone1: '',
				department: '',
				jobtitle: '',
				_axt_servicebundle_value: {},
				listaProdutoComplemento: [],
				axt_userprincipalname: '',
				emailaddress1: '',
				SufixMail: '',
				listaLinhaServico: '',
				listaPerfis: '',
				emailVisualization: '',
				HabilitarMFA: true
			},
			validations: {
				UsuarioAcessor: {},
				axt_onpremisessyncenabled: {},
				adx_organizationname: {},
				axt_extensionattribute3: {},
				axt_extensionattribute4: {},
				firstname: {},
				lastname: {},
				axt_displayname365: {},
				mobilephone: { mask: 'phone' },
				telephone1: { mask: 'phone' },
				axt_extensionattribute8: { mask: 'email' },
				emailaddress2: { mask: 'email' },
				axt_extensionattribute5: {},
				department: {},
				jobtitle: {},
				_axt_servicebundle_value: {},
				listProductAddons: {},
				axt_extensionattribute1: {},
				axt_userprincipalname: { mask: 'email' },
				emailaddress1: { mask: 'email' },
				SufixMail: { showErrorCurrentItem: true, mask: /^@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ },
				listaLinhaServico: { required: true, hasError: true, errorMensage: "Adicione pelo menos um perfil de acesso" },
				listaPerfis: { required: true, hasError: true, errorMensage: "Adicione pelo menos um perfil de acesso" },
				emailVisualization: {},
				HabilitarMFA: {}
			},
			hasError: {
				UsuarioAcessor: {},
				axt_onpremisessyncenabled: {},
				adx_organizationname: {},
				axt_extensionattribute3: {},
				axt_extensionattribute4: {},
				firstname: {},
				lastname: {},
				axt_displayname365: {},
				mobilephone: {},
				telephone1: {},
				axt_extensionattribute8: {},
				axt_extensionattribute5: {},
				emailaddress2: {},
				department: {},
				jobtitle: {},
				_axt_servicebundle_value: {},
				listProductAddons: {},
				axt_extensionattribute1: {},
				axt_userprincipalname: {},
				emailaddress1: {},
				SufixMail: {},
				listaLinhaServico: {},
				listaPerfis: {},
				emailVisualization: {},
				HabilitarMFA: {}
			}
		};

		this.onBlurAdvisorCode = this.onBlurAdvisorCode.bind(this);
		this.onConfirmModal = this.onConfirmModal.bind(this);
		this.onCloseModal = this.onCloseModal.bind(this);
		this.renderModal = this.renderModal.bind(this);
		this.alertExtensionAttribute8 = this.alertExtensionAttribute8.bind(this);
		this.ehParaExibirERequererPerfilDeAcesso = this.ehParaExibirERequererPerfilDeAcesso.bind(this);
		this.onCloseModalValidCreatingUser = this.onCloseModalValidCreatingUser.bind(this);
		this.onChangeAddons = this.onChangeAddons.bind(this);
		this.onSelectChange = this.onSelectChange.bind(this);
		this.refreshPage = this.refreshPage.bind(this);
	}

	refreshPage() {
		this.setState({ loadingForm: true });
		window.location.reload(false);
	}

	onChangeAddons(obj) {
		const ammout = obj.map(o => o.amount).reduce((a, c) => a + c, 0).toFixed(2);

		const addonAmount = Number(ammout)
		const addonCurrencyName = obj.map(o => o.currencyName)[0]
		const addonCurrencySymbol = obj.map(o => o.currencySymbol)[0]

		this.setState({
			addonAmount,
			addonCurrencyName,
			listaProdutoComplementoExibicao: obj,
			addonCurrencySymbol,
			form: {
				...this.state.form,
				listaProdutoComplemento: obj.map(o => { return { value: o.value, UnidadeId: o.unitId } })
			}
		})
	}

	async onSelectChange(event) {
		event.persist();
		var dados = event.target.value.split(';')

		const value = dados[0];
		const priceLevelCurrencySymbol = dados[1];
		const priceLevelAmount = event.target.value !== "" ? dados[2] : 0;
		const priceLevelCurrencyName = event.target.value !== "" ? dados[3] : '';
		const unitId = event.target.value !== "" ? dados[4] : '';

		const newValue = value ? { value: value, UnidadePrincipalId: unitId } : {};

		await this.setState({
			priceLevelCurrencySymbol,
			priceLevelAmount,
			priceLevelCurrencyName,
			LicensePlanExibicao: event.target.value,
			form: {
				...this.state.form,
				_axt_servicebundle_value: newValue
			},
			validations: {
				...this.state.validations,
				_axt_servicebundle_value: {
					...this.state.validations._axt_servicebundle_value,
					hasError: false,
					showErrorCurrentItem: true
				}
			}
		}, () => { this.validateInput(event) });

		this.setState({ searchBundleLimit: true });

		const bundleLimit = await checkBundleLimit(dados[0]);

		this.setState({ searchBundleLimit: false });

		if (!bundleLimit.licencaDisponivel) {
			const errorText = bundleLimit.mensagem ?? 'Erro ao tentar validar licença. Favor entre em contato com nosso atendimento.';

			this.setState({
				validations: {
					...this.state.validations,
					_axt_servicebundle_value: {
						...this.state.validations._axt_servicebundle_value,
						hasError: true,
						errorMensage: errorText
					}
				}
			}, () => { this.validateInput(event) })
		} else if (!this.state.form?._axt_servicebundle_value?.value) {
			this.setState({
				validations: {
					...this.state.validations,
					_axt_servicebundle_value: {
						...this.state.validations._axt_servicebundle_value,
						hasError: true,
						errorMensage: 'Campo obrigatório'
					}
				}
			}, () => { this.validateInput(event) })
		} else {
			this.setState({
				validations: {
					...this.state.validations,
					_axt_servicebundle_value: {
						...this.state.validations._axt_servicebundle_value,
						hasError: false,
						errorMensage: ''
					}
				}
			}, () => { this.validateInput(event) })
		}
	}

	componentDidMount = async () => {
		/* Starts the form load */
		this.setState({
			loadingForm: true,
			loadingAutorizacao: true,
		});

		await this.permitirGestaoUsuario();

		/* Search for the form items */
		await this.props.rdxClearUsers(true);
		this.props.rdxGetUserFields();
		this.props.rdxGetPerfis();
		this.props.rdxGetLinhaServico();



		/* Busca dados do produto direto no dynamics */
		this.props.rdxGetPlansRightsByEntitlementId();

		/* Busca dados de complemento do produto direto no dynamics */
		this.props.rdxGetAdditionalProductsRightByEntitlementId();

		/* Busca dados da empresa do tenant/contrato/direito em gestão */
		await this.props.rdxGetAccountById();

		/* Busca dados da empresa */
		let userSignature = JSON.parse(sessionStorage.getItem('signature'));

		const sufixos = await DominiosService.ListarSufixosPorCliente();
		const sufixList = sufixos.map(sufixo => `@${sufixo}`);

		let newValues = {
			adx_organizationname: userSignature.accountName || '',
			axt_extensionattribute3: this.props.account.axt_razaosocial || '',
			axt_extensionattribute4: this.props.account.axt_cnpjview || '',
			SufixMail: sufixList[0]
		}
		let newForm = { ...this.state.form, ...newValues };

		this.setState({
			form: newForm,
			sufixList: [...sufixList],
			sufixLoading: false
		});

		/* Verifica se usuário é gesto de cotrato da empresa */
		this.setState({ showPerfisPortal: TemAcessoAosProfiles([profilesV2.admGlobal, ...profileNavGerenciamentoUsuarios]) })
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.form.emailaddress1 !== prevState.form.emailaddress1 || this.state.form.SufixMail !== prevState.form.SufixMail) {
			let newEmailVisualization = this.state.form.emailaddress1 && this.state.form.SufixMail ? this.state.form.emailaddress1 + this.state.form.SufixMail : '';
			this.setState({
				form: { ...this.state.form, emailVisualization: newEmailVisualization }
			});
		}
	}

	componentWillReceiveProps(nextProps) {
		let newObj = {};
		let newRequiredObj = {};
		let newForm = { ...this.state.form };
		let listaPerfis = [];

		nextProps.listaFormFields.forEach(el => {
			let newValueState = {
				...el,
				obrigatorio: !el.somenteLeitura && el.obrigatorio,
				visible: true,
				lista: el.valores
			};

			newObj[el.atributo] = this.state.formContent[el.atributo] ? Object.assign(this.state.formContent[el.atributo], newValueState) : newValueState;

			if (!el.somenteLeitura && el.obrigatorio) {
				newRequiredObj[el.atributo] = { ...this.state.validations[el.atributo], required: true }
			}
			else {
				newRequiredObj[el.atributo] = this.state.validations[el.atributo];
			}
		})

		newForm = {
			...newForm,
			listaLinhaServico: nextProps.listaLinhaServico.length ? nextProps.listaLinhaServico[0].value : '',
			listaPerfis: nextProps.listaPerfis.length ? CONSTANTES_AMBIENTE.PERFIL_ACESSO.USUARIO_FINAL : '',
			axt_onpremisessyncenabled: newObj.axt_onpremisessyncenabled && (newObj.axt_onpremisessyncenabled.valores.length === 0 || newObj.axt_onpremisessyncenabled.valores[0].valor === "true") ? false : false
		}

		if (this.state.formContent.SufixMail.visible) {
			newRequiredObj.emailaddress1.mask = '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+$';
		}

		if (newObj.axt_extensionattribute5 && newObj.axt_extensionattribute5.visible) {
			this.setState({
				hasAcessor: true,
			});
		}

		if (newObj._axt_servicebundle_value)
			this.setState({ renderizarPlanodeEmail: true });

		listaPerfis = nextProps.listaPerfis.length ? nextProps.listaPerfis : [];

		if (newObj.axt_extensionattribute5 && newObj.axt_extensionattribute5.visible) {
			newObj.axt_extensionattribute1.obrigatorio = false;
			newObj.axt_extensionattribute1.visible = false;
			newObj.axt_extensionattribute1.somenteLeitura = true;
			newRequiredObj.axt_extensionattribute1.required = false;

			listaPerfis = [{
				label: "Gestor de Contrato",
				value: 282390000
			}, {
				label: "Usuário Final",
				value: 282390008
			}]
		}

		const { tenantName } = JSON.parse(sessionStorage.getItem('signature'));

		// Seta o campo e-mail como read only
		if (nextProps.listaFormFields[0]?.formName && (getFormId() == CONSTANTES_AMBIENTE.FORMULARIO_USUARIOS.BANCO_MODAL || getFormId() == CONSTANTES_AMBIENTE.FORMULARIO_USUARIOS.CONSULTORES_XP)) {
			newObj.emailaddress1.somenteLeitura = true;
		}

		this.setState({
			loadingForm: (nextProps.listaFormFields[0] && nextProps.listaFormFields[0].formName) || nextProps.listaFormFields.length === 0 ? false : true,
			formContent: { ...this.state.formContent, ...newObj },
			validations: { ...this.state.validations, ...newRequiredObj },
			form: { ...newForm },
			formHeaders: {
				formName: nextProps.listaFormFields[0] && nextProps.listaFormFields[0].formName,
				formTitle: 'Criar novo usuário',
				tenant: tenantName
			},
			listaPerfis: listaPerfis
		});
	}

	async permitirGestaoUsuario() {
		await GetDireitoPorId()
			.then(response => {
				if (response?.permitirGestaoUsuario === false)
					this.setState({ autorizado: false });
			})
			.finally(() => {
				this.setState({ loadingAutorizacao: false });
			})
	}

	async onBlurAdvisorCode(event) {
		const value = event.target.value;

		if (event.target.value) {
			this.setState({ codassessor: '', modalOpen: '', controlAdvisorCod: false, messageAdvisorCod: '' })

			this.setState({ advisorCodIsLoading: true });
			const isConsultantCode = getFormId() == CONSTANTES_AMBIENTE.FORMULARIO_USUARIOS.CONSULTORES_XP;
			const verificaCodigoAssessor = isConsultantCode ? await verifyConsultantCode(value) : await AssessoresService.ChecarCodigoAssessor(value);
			this.setState({ advisorCodIsLoading: '' });

			if (+verificaCodigoAssessor.resultCode === 1) {
				this.setState({ codassessor: '', modalOpen: '', controlAdvisorCod: true, messageAdvisorCod: '' });
			} else {
				this.setState({ codassessor: value, controlAdvisorCod: false, messageAdvisorCod: verificaCodigoAssessor.message });
			}

			if (+verificaCodigoAssessor.resultCode === 0) {
				this.setState({ modalOpen: "CodAssessor" });
			}
		}
	}

	alertExtensionAttribute8(e) {
		if (e.type === "focus") {
			this.setState({ focusExtensionAttribute8: true });
		} else {
			this.setState({ focusExtensionAttribute8: false });
		}
	}

	onConfirmModal() {
		this.setState({ codassessor: '', modalOpen: '', controlAdvisorCod: true, messageAdvisorCod: '' });
	}

	onCloseModal() {
		this.setState({ codassessor: '', modalOpen: '', controlAdvisorCod: false, messageAdvisorCod: 'Código de assessor já utilizado por outro usuário.' });
	}

	onCloseModalValidCreatingUser() {
		this.setState({ modalOpen: '' })
	}

	renderModal() {
		if (this.state.modalOpen === 'CodAssessor') {
			return (
				<ValidateCod onConfirmModal={this.onConfirmModal} onCloseModal={this.onCloseModal} cod={this.state.codassessor} ></ValidateCod>
			);
		}
		else if (this.state.modalOpen === 'ValidCreatingUser') {
			return (
				<Modal open={true} close={this.onCloseModalValidCreatingUser}>
					<div className="text-modal">
						<p><IoIosInformationCircleOutline size={130} color="#FFF19D" /> </p>
						<p> {this.state.operationUserLog.modalContent} </p>
					</div>
					<div className="btn-modal">
						<button type="button" onClick={this.onCloseModalValidCreatingUser} className="btn btn-default">Entendi</button>
					</div>
				</Modal>
			)
		}
		else if (this.state.modalOpen === 'ResultadoValidacaoCriar') {
			return (
				<ModalGestaoUsuarioResponse
					openModal={true}
					onCloseModal={this.onCloseModalValidCreatingUser.bind(this)}
					responseError={this.state.criarUsuarioErro}
					response={this.state.criarUsuarioResponse}
				/>
			)
		}
	}

	renderField(obj, id, cols, customProps) {
		if (obj.visible) {
			let configInput = [id];

			if (obj.mask) {
				configInput = [...configInput, el => { el.target.value = Mask(el.target.value, obj.mask); }]
			}

			let props = {
				id: id,
				label: obj.nome + (obj.obrigatorio ? '*' : ''),
				readOnly: obj.somenteLeitura,
				configureInput: this.configureInput(...configInput),
				items: obj.lista.length ? [{ valor: '', label: 'Selecione' }, ...obj.lista.map(el => { return { value: el.valor, label: el.textoExibicao } })] : [],
				...customProps
			};

			if (obj.tamanho) {
				props.maxLength = obj.tamanho;
			}

			if (obj.lista.length) {
				return (
					<div className={cols}>
						<Select {...props} />
					</div>
				)
			}
			else {
				if (id === 'axt_extensionattribute5') {
					return (
						<div className={cols} onBlur={this.onBlurAdvisorCode}>
							<Input loading={this.state.advisorCodIsLoading} alertControlAdvisor={this.state.messageAdvisorCod} advisorControl={this.state.controlAdvisorCod} {...props} />
						</div>
					)
				} else if (id === "axt_extensionattribute8") {
					return (
						<div className={`tooltip-alerts ${cols}`}>
							<Input onFocus={this.alertExtensionAttribute8} {...props} />
							<span className={`tooltip-message ${this.state.focusExtensionAttribute8 ? '' : 'd-none'}`}>
								Um e-mail com a nova senha será enviado para este e-mail alternativo.
							</span>
						</div>
					)
				} else if (id === 'emailaddress1') {
					return (
						<div className={cols}>
							<Input {...props} />
							<div style={{ marginTop: -15, marginBottom: 10 }}>
								<span>
									{(this.state.form.emailaddress1 && !this.state.validations.emailaddress1.hasError) && this.state.form.emailaddress1 + this.state.form.SufixMail}
								</span>
							</div>
						</div>
					)
				} else {
					return (
						<div className={cols}>
							<Input {...props} />
						</div>
					)
				}
			}
		}

		return ''
	}

	handleChangeHabilitarMFA = (e) => {
		this.setState({
			...this.state,
			form: {
				...this.state.form,
				HabilitarMFA: e.target.checked
			}
		});
    }

	renderHabilitarMFA() {
		const HabilitarMFA = this.state.formContent.HabilitarMFA;

		const textoTooltip = HabilitarMFA.somenteLeitura ? "Para garantir a segurança do ambiente, o MFA é obrigatório e não pode ser desabilitado." :
			"Recomendamos manter o MFA habilitado, pois ele ajuda a proteger sua conta exigindo uma segunda etapa de verificação, dificultando acessos não autorizados, mesmo que sua senha seja comprometida.";

		return (
			<div className="col-12 col-lg-6">
				<div className="form-group form-group-checkbox">
					<Tooltip title={textoTooltip}>
						<>
						<CheckBox
							id="HabilitarMFA"
							label={HabilitarMFA.nome}
							checked={this.state.form.HabilitarMFA}
							onChange={this.handleChangeHabilitarMFA}
							disabled={HabilitarMFA.somenteLeitura}
						/>
						</>
					</Tooltip>
				</div>
			</div>
		);
	}

	formularioEhValido() {
		var errors = Object.entries(this.state.hasError);

		for (let index = 0; index < errors.length; index++) {
			let error = errors[index];

			if (Object.keys(error[1]).length > 0)
				return false;
		}

		return true;
	}

	controlViewPerfisDeAcesso() {
		if (this.arrayHasItems(this.state.profiles)) {
			this.setState({
				validations: {
					...this.state.validations,
					listaLinhaServico: {
						...this.state.validations.listaLinhaServico,
						required: false
					},
					listaPerfis: {
						...this.state.validations.listaPerfis,
						required: false
					}
				}
			})
		}
	}

	ehParaExibirERequererPerfilDeAcesso() {
		return ((!this.state.hasAcessor) || (this.state.hasAcessor && this.props.listaLinhaServico.length > 0));
	}

	renderFilterSelect(nameField, list, loading) {
		const items = list.length ? [{ value: '', label: 'Selecione' }, ...list.map(el => { return { value: el.value + ';' + el.currencySymbol + ';' + el.amount + ';' + el.currencyName + ';' + el.unitId, label: el.label } })] : []

		return (
			<React.Fragment>
				<Select
					label="Plano de E-mail*"
					items={items}
					configureInput={this.configureInputCustom("LicensePlanExibicao", nameField, event => this.onSelectChange(event))}
					loading={loading}
				/>
				<div style={{ marginTop: -15 }}>
					{this.state.priceLevelAmount > 0 &&
						<span style={{
							fontSize: 12,
							marginLeft: 5,
							letterSpacing: '0.33px'
						}}>
							Valor da licença em {this.state.priceLevelCurrencyName.toLowerCase()}: {this.state.priceLevelCurrencySymbol} {this.state.priceLevelAmount}
						</span>
					}
				</div >
			</React.Fragment>

		)
	}

	renderValorTotalDoPlano() {
		const totalAmount = Number(this.state.addonAmount) + Number(this.state.priceLevelAmount)
		return (
			<div className="col-12 col-sm-12 col-lg-12" style={{
				justifyContent: 'flex-end',
				display: 'flex',
				padding: 10
			}}>
				{(this.state.priceLevelAmount > 0) &&
					<span>
						Valor total do plano em {this.state.priceLevelCurrencyName.toLowerCase()}: {this.state.priceLevelCurrencySymbol} {totalAmount.toFixed(2)}
					</span>
				}
			</div>
		)
	}

	renderListaPacoteCspElicencasOpcionais() {
		return (
			<React.Fragment>
				<div className="col-12 col-sm-6 col-lg-6">
					{this.renderFilterSelect("_axt_servicebundle_value", this.props.listaDePacotesCSP, this.props.listaDePacotesCSPLoading || this.state.searchBundleLimit)}
				</div>
				<div className="col-12 col-sm-6 col-lg-6">
					<label>{"Licenças Opcionais"}</label>
					<div className="input-group-container">
						<CustomSelect
							className="input-group filter-select"
							value={this.state.listaProdutoComplementoExibicao}
							data={this.props.listaProdutoComplemento}
							multiple={true}
							disabled={false}
							onChange={this.onChangeAddons}
						/>
						{this.state.addonAmount > 0 &&
							<span style={{
								fontSize: 12,
								marginLeft: 5,
								letterSpacing: '0.33px'
							}}>
								Valor total das licenças opcionais em {this.state.addonCurrencyName.toLowerCase()}: {this.state.addonCurrencySymbol} {this.state.addonAmount}
							</span>
						}
						<div className={`simple-loading ${(!this.props.listaProdutoComplementoLoading) ? 'd-none' : ''}`}></div>
					</div>
				</div>
			</React.Fragment >
		)
	}

	renderPerfisDeAcesso() {
		if (this.ehParaExibirERequererPerfilDeAcesso()) {
			if (this.state.showPerfisPortal) {
				let selectedsLines = this.state.profiles.map(e => e.DireitoId)
				let listItemsFiltered = this.props.listaLinhaServico.filter(e => selectedsLines.indexOf(e.value) < 0)

				return (
					<PerfisDeAcesso erroPerfisDeAcesso={this.state.erroPerfisDeAcesso}>
						<div className="perfis">
							<h2>Perfis de acesso ao atendimento</h2>
							<ContentBox className="form-container">
								<div className="row">
									<div className="col-12 col-sm-6 col-lg-4">
										<Select
											label="Linha de serviço"
											items={listItemsFiltered}
											configureInput={this.configureInput('listaLinhaServico')}
											loading={this.props.listaLinhaServicoLoading}
											disabled={listItemsFiltered.length === 0}
										/>
									</div>
									<div className="col-12 col-sm-6 col-lg-4 col-xl-5">
										<Select
											label="Perfil"
											items={this.state.listaPerfis}
											configureInput={this.configureInput('listaPerfis')}
											loading={this.props.listaPerfisLoading}
											disabled={listItemsFiltered.length === 0}
										/>
									</div>
									<div className="col-12 col-lg-4 col-xl-3 add-publico-alvo">
										<button
											type="button"
											className="btn btn-default"
											onClick={this.onClickAddProfile.bind(this)}
											disabled={this.props.listaLinhaServicoLoading || this.props.listaPerfisLoading || listItemsFiltered.length === 0}>
											Adicionar perfil de acesso
										</button>
									</div>
								</div>

								<span className={`error-duplicated ${this.state.erroIncluirPerfil ? '' : 'd-none'}`}>{this.state.erroIncluirPerfil}</span>

								<div className="container-result">
									<div className={`container-publico-alvo ${this.state.profiles.length === 0 ? 'd-none' : ''}`}>
										<span className="title">Perfis associados ao usuário:</span>
										<div className="row results">
											{
												this.state.profiles.map(obj => (
													<div key={obj.DireitoId + obj.PerfilDeAcesso} className="col-12 col-sm-6 col-lg-4 result">
														<div className="container-result">
															<div className="result-details">
																<span className="label">Linha de serviço:</span>
																<span className="value">{this.props.listaLinhaServico.filter(el => el.value === obj.DireitoId)[0].label}</span>
																<span className="label">Perfil:</span>
																<span className="value">{this.props.listaPerfis.filter(el => el.value.toString() === obj.PerfilDeAcessoCodigo.toString())[0].label}</span>
															</div>
															<button className="btn btn-danger" onClick={() => this.onClickRemoveProfile(obj)}>Excluir</button>
														</div>
													</div>
												))
											}
										</div>
									</div>
									<span className={`no-results${this.state.profiles.length > 0 ? ' d-none' : ''}`}>Nenhum perfil associado</span>
								</div>
							</ContentBox>
						</div>
					</PerfisDeAcesso>
				)
			}
		} else {
			return '';
		}
	}

	renderFieldCheckBoxAcessor() {
		const onChangeCheckbox = e => {
			this.setState({
				formContent: {
					...this.state.formContent,
					axt_extensionattribute5: {
						...this.state.formContent.axt_extensionattribute5,
						visible: e.target.checked
					}
				},
				form: {
					...this.state.form,
					UsuarioAcessor: e.target.checked,
					axt_extensionattribute5: e.target.checked ? this.state.axt_extensionattribute5 : ''
				},
				validations: {
					...this.state.validations,
					axt_extensionattribute5: {
						...this.state.validations.axt_extensionattribute5,
						required: e.target.checked
					},
				}
			})
		}

		if (this.state.hasAcessor) {
			return (
				<div className="col-12 col-lg-6">
					<div className="form-group form-group-checkbox">
						<CheckBox
							id="UsuarioAcessor"
							label={getLabelCheckboxAssessor()}
							checked={getFormId() == CONSTANTES_AMBIENTE.FORMULARIO_USUARIOS.CONSULTORES_XP ? true : this.state.form.UsuarioAcessor}
							onChange={onChangeCheckbox}
							disabled={getFormId() == CONSTANTES_AMBIENTE.FORMULARIO_USUARIOS.CONSULTORES_XP ? true : false}
						/>
					</div>
				</div>
			)
		}
	}

	renderFieldCheckBoxSyncOrCloud() {
		if (this.state.formContent.axt_onpremisessyncenabled.visible) {

			const tooltip = () => {
				if (this.state.formContent.axt_onpremisessyncenabled.somenteLeitura) {
					if (this.state.form.axt_onpremisessyncenabled) {
						return (
							<span className="tooltip">
								Seu ambiente não está preparado para integração com Active Directory(AD)
							</span>
						)
					}
					else {
						return (
							<span className="tooltip">
								Não é possível criar usuários em nuvem devido a restrições em seu ambiente.
							</span>
						)
					}
				}
			}

			return (
				<div className="col-12 col-lg-6" style={{ display: 'flex' }}>
					<div className="form-group check-user-nuvem" style={{ alignSelf: 'center' }}>
						<CheckBox
							id="UsuarioNuvem"
							label="Criar usuário em nuvem (Azure AD)"
							checked={this.state.form.axt_onpremisessyncenabled}
							onChange={e => this.setState({ form: { ...this.state.form, axt_onpremisessyncenabled: e.target.checked } })}
							disabled={this.state.formContent.axt_onpremisessyncenabled.somenteLeitura} />

						{tooltip()}
					</div>
				</div>
			)
		}
	}

	onClickAddProfile() {
		let service = this.state.form.listaLinhaServico.toString();
		let profile = this.state.form.listaPerfis.toString();

		let direitoNovo = this.props.listaLinhaServico.filter(
			(e) => e.value == service
		)[0];

		let perfilAcessoNovo = this.props.listaPerfis.filter(
			(e) => e.value == profile
		)[0];

		let newValue = {
			PerfilDeAcesso: perfilAcessoNovo.label,
			PerfilDeAcessoCodigo: perfilAcessoNovo.value.toString(),
			DireitoId: direitoNovo.value,
			LinhaDeServico: direitoNovo.LinhaDeServico,
			Direito: direitoNovo.Direito,
			Rotulo: direitoNovo.label
		};


		let isDuplicated = this.state.profiles.filter(el => JSON.stringify(el) === JSON.stringify(newValue)).length > 0;

		if (!isDuplicated) {
			let newSelectedItems = [...this.state.profiles, newValue];
			this.setState({ profiles: newSelectedItems, erroIncluirPerfil: '' }, () => {
				if (this.state.profiles.length) {
					this.setState({
						validations: {
							...this.state.validations,
							listaLinhaServico: { ...this.state.validations.listaLinhaServico, required: false, hasError: false },
							listaPerfis: { ...this.state.validations.listaPerfis, required: false, hasError: false }
						}
					});

					this.formValido(['UsuarioAcessor', 'listaLinhaServico', 'listaPerfis']);
				}
			});

			let selectedsLines = newSelectedItems.map(e => e.DireitoId)
			let listItemsFiltered = this.props.listaLinhaServico.filter(e => selectedsLines.indexOf(e.value) < 0)

			if (listItemsFiltered[0] && this.state.form.listaLinhaServico !== listItemsFiltered[0].value) {
				this.setState({ form: { ...this.state.form, listaLinhaServico: listItemsFiltered[0].value } })
			}

			this.setState({ erroPerfisDeAcesso: false });
		}
		else {
			this.setState({ erroIncluirPerfil: 'Perfil já adicionado.' });
		}
	}

	onClickRemoveProfile(obj) {
		this.setState(prevState => ({
			profiles: prevState.profiles.filter(el => JSON.stringify(el) !== JSON.stringify(obj)),
			erroIncluirPerfil: ''
		}), () => {
			if (!this.state.profiles.length) {
				this.setState({
					erroPerfisDeAcesso: true,
					validations: {
						...this.state.validations,
						listaLinhaServico: { ...this.state.validations.listaLinhaServico, required: true, hasError: true },
						listaPerfis: { ...this.state.validations.listaPerfis, required: true, hasError: true }
					}
				});
			}
		});
	}

	generateUserMail = (firstname, lastname, accountid, domain, mstenantcode) => {
		const emailConfig = {
			accountid,
			firstname,
			lastname,
			domain,
			mstenantcode
		}

		return generateEmail(emailConfig);
	}

	onBlurName = async () => {
		const {
			firstname,
			lastname,
			SufixMail
		} = this.state.form;

		this.setState({
			form: {
				...this.state.form,
				axt_displayname365: `${this.state.form.firstname} ${this.state.form.lastname}`
			},
			hasError: {
				...this.state.hasError,
				axt_displayname365: (!!firstname && !!lastname) ? {} : { "required": "*Campo obrigatório" }
			}
		});

		document.querySelector('#axt_displayname365').focus();

		if (getFormId() == CONSTANTES_AMBIENTE.FORMULARIO_USUARIOS.BANCO_MODAL || getFormId() == CONSTANTES_AMBIENTE.FORMULARIO_USUARIOS.CONSULTORES_XP) {
			const {
				accountId,
				tenantCode
			} = JSON.parse(sessionStorage.getItem('signature'));

			if (!!firstname && !!lastname) {
				this.setState({ searchEmail: true });
				NotificationManager.warning(
					'O e-mail principal está sendo gerado.',
					'O e-mail principal do usuário está sendo gerado de forma automática!',
					5000);

				const newEmail = await this.generateUserMail(firstname, lastname, accountId, SufixMail.slice(1), tenantCode);

				this.setState({ searchEmail: false });

				if (!!newEmail.emailaddress) {
					NotificationManager.success(
						'O e-mail principal foi gerado.',
						'O e-mail principal do usuário foi gerado de forma automática e o campo está disponível para alteração.',
						5000);

					this.setState({
						form: {
							...this.state.form,
							emailaddress1: newEmail.emailaddress.split('@')[0]
						},
						formContent: {
							...this.state.formContent,
							emailaddress1: {
								...this.state.formContent.emailaddress1,
								somenteLeitura: false,
							}
						}
					});
				} else {
					NotificationManager.error(
						'Houve um erro na geração do e-mail.',
						'Houve um erro na geração automática de e-mail. O campo e-mail está disponível para ser inserido.',
						5000);

					this.setState({
						formContent: {
							...this.state.formContent,
							emailaddress1: {
								...this.state.formContent.emailaddress1,
								somenteLeitura: false,
							}
						}
					});
				}

				document.querySelector('#emailaddress1').focus();
			}
		}
	}

	onBlurSufix = (event) => {
		this.setState({
			...this.state,
			form: {
				...this.state.form,
				SufixMail: event.target.value
			}
		});

		document.querySelector('#emailaddress1').focus();
	}

	onBlurEmail = async el => {
		el.persist();

		const inputValue = el.target.value.indexOf('@') !== -1 ? el.target.value.split('@')[0] : el.target.value;

		await this.setState({
			validations: {
				...this.state.validations,
				emailaddress1: {
					...this.state.validations.emailaddress1,
					hasError: false,
					showErrorCurrentItem: true
				}
			}
		});

		if (inputValue.endsWith('.')) {
			return this.setState({
				validations: {
					...this.state.validations,
					emailaddress1: {
						...this.state.validations.emailaddress1,
						hasError: true,
						errorMensage: 'O e-mail não pode conter um "." antes do "@".'
					}
				}
			}, () => { this.validateInput(el) });
		}

		if (this.validateInput(el)) {
			let errorText = '';
			this.setState({ searchEmail: true })

			await EmailsService.ValidarEmailPrincipal(`${el.target.value + this.state.form.SufixMail}`)
				.then(() => {
					this.setState({ searchEmail: false });
				})
				.catch((error) => {
					errorText = error.response?.data?.erros[0] ?? 'Erro ao validar o email principal.';

					this.setState({
						searchEmail: false,
						validations: {
							...this.state.validations,
							emailaddress1: {
								...this.state.validations.emailaddress1,
								hasError: true,
								errorMensage: errorText
							}
						}
					}, () => { this.validateInput(el) })
				});
		}
	}

	onBlurAlternateEmail = async el => {
		el.persist();

		await this.setState({
			validations: {
				...this.state.validations,
				axt_extensionattribute8: {
					...this.state.validations.axt_extensionattribute8,
					hasError: false,
					showErrorCurrentItem: true
				},
				emailaddress2: {
					...this.state.validations.emailaddress2,
					hasError: false,
					showErrorCurrentItem: true
				}
			}
		})

		if (this.validateInput(el)) {
			await this.setState({ searchAlternateEmail: true });

			await EmailsService.ValidarEmailAlternativo(`${el.target.value}`)
				.then(() => {
					this.setState({
						searchAlternateEmail: false
					})
				})
				.catch((error) => {
					this.setState({
						searchAlternateEmail: false,
						validations: {
							...this.state.validations,
							emailaddress2: {
								...this.state.validations.emailaddress2,
								hasError: true,
								errorMensage: error.response?.data.erros[0]
							}
						}
					}, () => { this.validateInput(el) });
				})
		}
	}

	onBlurUpn = async el => {
		el.persist();

		await this.setState({
			validations: {
				...this.state.validations,
				axt_userprincipalname: {
					...this.state.validations.axt_userprincipalname,
					hasError: false,
					showErrorCurrentItem: true
				}
			}
		});

		if (this.validateInput(el)) {
			let errorText = "";
			this.setState({ searchUpn: true })

			const check = await checkUpnAvailability(el.target.value);

			this.setState({ searchUpn: false });

			if (Number(check.resultcode) === -1) {
				errorText = check.message;

				this.setState({
					validations: {
						...this.state.validations,
						axt_userprincipalname: {
							...this.state.validations.axt_userprincipalname,
							hasError: true,
							errorMensage: errorText
						}
					}
				}, () => { this.validateInput(el) });
			}
		}
	}

	setCheckedPolicy = (value) => {
		this.setState({ checkedPolicy: value });
	}

	backPage = () => {
		this.setState({ loadingForm: true });
		this.setState({ checkedPolicy: false, timelinePosition: this.state.timelinePosition - 1 });
		this.setState({ loadingForm: false });
	}

	nextPage = () => {
	}

	handleSubmit = async (e) => {
		this.setState({ loadingForm: true });

		const { timelinePosition, isSubmit } = this.state;

		if (isSubmit) {
			return;
		}

		const currentTimeLinePosition = timelinePosition;
		this.setState({ isSubmit: currentTimeLinePosition >= 2 ? true : false });

		if (currentTimeLinePosition < 2) {
			let currentTarget = [];
			[...e.currentTarget].forEach(element => {
				currentTarget.push(element.name);
			});
			const valid = await this.validForm(currentTarget);
			if (valid) {
				this.setState({ timelinePosition: this.state.timelinePosition + 1 });
			}
		} else {
			this.onSubmitV2();
		}
		this.setState({ loadingForm: false });
	}

	validForm = async currentTarget => {
		this.setState({ showFormErrors: true });

		let valido = true;

		if (!this.state.profiles.length) {
			this.setState({ isSubmit: false, erroPerfisDeAcesso: true });
			valido &= false;
		}

		valido &= await this.formValido(currentTarget);

		return valido;
	};

	formValido = async (currentTarget) => {
		var valid = true;
		for (var name in this.state.form) {
			let current = currentTarget.includes && currentTarget.includes(name) ? await this.validateInput(name) : true;
			valid = valid && current;
		}

		return valid;
	};

	onSubmitV2 = () => {
		if (!this.state.form.UsuarioAcessor || this.state.controlAdvisorCod) {
			if (!this.state.creating || !this.state.controlAdvisorCod === false) {
				this.setState({ creating: true, loadingForm: true })
				this.setState({ showLoadingShadow: true });

				const {
					_axt_servicebundle_value,
					listaProdutoComplemento,
					axt_displayname365,
					axt_extensionattribute5,
					axt_extensionattribute8,
					axt_userprincipalname,
					department,
					emailaddress1,
					emailaddress2,
					SufixMail,
					jobtitle,
					mobilephone,
					telephone1,
					HabilitarMFA
				} = this.state.form;
				const primarySmtpAddress = (emailaddress1 + SufixMail).trim().replace(/\s|​/g, '');
				const advisorCode = axt_extensionattribute5.toString() ? axt_extensionattribute5.toString().trim() : axt_extensionattribute5.toString();
				const firstname = this.state.form.firstname.trim();
				const lastname = this.state.form.lastname.trim();
				const signature = JSON.parse(sessionStorage.getItem("signature"));
				const PerfisDeAcessoAosDireitos = this.state.profiles.
					map(perfil => { return { DireitoId: perfil?.DireitoId, PerfilDeAcesso: perfil?.PerfilDeAcessoCodigo } });

				const userData = {
					TenantId: signature.axtTenantId,
					LoginUsuario: axt_userprincipalname,
					DireitoId: signature.entitlementId,
					ProdutoPrincipal: { ProdutoPrincipalId: _axt_servicebundle_value.value, UnidadePrincipalId: _axt_servicebundle_value.UnidadePrincipalId },
					Cidade: null,
					Logradouro: null,
					Numero: null,
					Complemento: null,
					CEP: null,
					Estado: null,
					ProdutosOpcionais: listaProdutoComplemento.map(addon => { return { ProdutoId: addon.value, UnidadeId: addon.UnidadeId } }),
					NomeParaExibicao: axt_displayname365,
					PerfisDeAcessoAosDireitos: PerfisDeAcessoAosDireitos,
					AtributoEstendido1: null,
					AtributoEstendido2: null,
					AtributoEstendido3: null,
					AtributoEstendido4: null,
					AtributoEstendido5: advisorCode,
					AtributoEstendido6: null,
					AtributoEstendido7: null,
					AtributoEstendido8: null,
					AtributoEstendido9: null,
					AtributoEstendido10: null,
					AtributoEstendido11: null,
					AtributoEstendido12: null,
					AtributoEstendido13: null,
					AtributoEstendido14: null,
					AtributoEstendido15: null,
					Departamento: department,
					EmailPrincipal: primarySmtpAddress,
					EmailAlternativo: axt_extensionattribute8 ? axt_extensionattribute8 : emailaddress2,
					MatriculaDoFuncionario: null,
					Nome: firstname,
					Sobrenome: lastname,
					Cargo: jobtitle,
					TelefoneCelular: mobilephone,
					Apelido: null,
					TelefoneComercial: telephone1,
					TermoContratoAceito: isTenantNCE() ? JSON.stringify(policiesObject) : '',
					HabilitarMFA: HabilitarMFA
				}

				UsuariosService.CriarUsuario(userData)
					.then((response) => {
						this.setState({ criarUsuarioErro: false });
						this.setState({ criarUsuarioResponse: [response] });
						this.setState({ timelinePosition: this.state.timelinePosition + 1 });
					})
					.catch((error) => {
						this.setState({ criarUsuarioErro: true });
						this.setState({ criarUsuarioResponse: error.response?.data?.erros ?? ['Erro ao tentar criar usuário.'] });
					})
					.finally(() => {
						this.setState({ creating: false, loadingForm: false });
						this.setState({ isSubmit: false });
						this.setState({ showLoadingShadow: false });
						this.setState({ modalOpen: "ResultadoValidacaoCriar" });
					});
			}
		} else {
			this.setState({ isSubmit: false });
			NotificationManager.error('O código de assessor já está sendo utilizado.', 'Não é possivel criar o usuário!', 7000);
		}
	}

	renderLoadingShadow() {
		return (
			<LoadingShadow
				sizeClassName={''}
				open={true}
				close={() => { }}
			/>
		);
	}

	render() {
		const {
			formHeaders,
			loadingForm,
			loadingAutorizacao,
			autorizado,
			sufixList,
			sufixLoading,
			searchEmail,
			timelinePosition,
			accepted,
			checkedPolicy,
			showLoadingShadow
		} = this.state;

		if (loadingForm || sufixLoading || loadingAutorizacao) {
			return (
				<MasterPage>
					<div className="small-loading">
						<div className="simple-loading"></div>
					</div>
				</MasterPage>
			);
		}
		else if (!autorizado) {
			return <ErroGestaoUsuario />;
		}
		else if (!sufixList.length) {
			return (
				<MasterPage>
					<h1>Criar novo usuário</h1>
					<h2>Não há nenhum domínio no cadastro da sua empresa</h2>
					<h3>Não foi possível encontrar uma lista de domínios da sua empresa, portanto, não é possível realizar o cadastro de novos usuários.</h3>
				</MasterPage>
			)
		} else if (this.props.listaFormFields.length === 0) {
			return (
				<MasterPage>
					<h1>Criar novo usuário</h1>
					<h2>Não há nenhum formulário no cadastro do tenant</h2>
					<h3>Não foi possível encontrar um formulário no cadastro do tenant, portanto, não é possível realizar o cadastro de novos usuários. Entre em contato com nossa equipe de atendimento.</h3>
				</MasterPage>
			)
		} else {
			return (
				<MasterPage>
					<FormHeader formHeaders={formHeaders} />
					<Timeline
						timelinePosition={timelinePosition}
						accepted={accepted}
						timelineLabelItems={['Dados do usuário', 'Licenciamento e planos', 'Confirmação e finalização']}
					/>

					<ContentWrapperStyle>
						<form onSubmit={e => { e.preventDefault(); this.handleSubmit(e) }}>
							{timelinePosition === 0 && (
								//Dados do usuário
								<div>
									<h2>Informações da empresa</h2>
									<h3>Dados da empresa em que o usuário será cadastrado</h3>
									<ContentBox className="form-container">
										<div className="row">
											{this.renderField(this.state.formContent.adx_organizationname, 'adx_organizationname', 'col-12 col-lg-12')}
											{this.renderField(this.state.formContent.axt_extensionattribute3, 'axt_extensionattribute3', 'col-12 col-lg-6')}
											{this.renderField(this.state.formContent.axt_extensionattribute4, 'axt_extensionattribute4', 'col-12 col-lg-6')}
										</div>
									</ContentBox>

									<h2>Dados do usuário</h2>
									<h3>Informe os dados do novo usuário</h3>
									<ContentBox className="form-container">
										<div className="row">
											{this.renderField(this.state.formContent.firstname, 'firstname', 'col-12 col-lg-6', { onBlur: this.onBlurName })}
											{this.renderField(this.state.formContent.lastname, 'lastname', 'col-12 col-lg-6', { onBlur: this.onBlurName })}
											{this.renderField(this.state.formContent.axt_displayname365, 'axt_displayname365', 'col-12 col-lg-6')}
											{this.renderField(this.state.formContent.mobilephone, 'mobilephone', 'col-12 col-lg-6')}
											{this.renderField(this.state.formContent.telephone1, 'telephone1', 'col-12 col-lg-6')}
											{this.renderField(this.state.formContent.axt_extensionattribute8, 'axt_extensionattribute8', 'col-12 col-lg-6', { onBlur: this.onBlurAlternateEmail, loading: this.state.searchAlternateEmail })}
											{this.renderField(this.state.formContent.emailaddress2, 'emailaddress2', 'col-12 col-lg-6', { onBlur: this.onBlurAlternateEmail, loading: this.state.searchAlternateEmail })}
											{this.renderField(this.state.formContent.department, 'department', 'col-12 col-lg-6')}
											{this.renderField(this.state.formContent.jobtitle, 'jobtitle', 'col-12 col-lg-6')}
											{this.renderFieldCheckBoxAcessor()}
											{this.renderField(this.state.formContent.axt_extensionattribute5, 'axt_extensionattribute5', 'col-12 col-lg-6')}

											{this.renderField(this.state.formContent.emailaddress1, 'emailaddress1', 'col-12 col-lg-6', { onBlur: this.onBlurEmail, loading: searchEmail })}
											<div className="form-group col-12 col-lg-6 sufixSelect">
												<label htmlFor="sufix">Domínio</label>
												<select
													id="sufix"
													className="input-group"
													onChange={event => this.onBlurSufix(event)}
													disabled={searchEmail}
												>
													{
														sufixList.map((sufix, sufixIndex) => (
															<option value={sufix} key={sufixIndex}>{sufix}</option>
														))
													}
												</select>
											</div>
											{this.renderField(this.state.formContent.axt_extensionattribute1, 'axt_extensionattribute1', 'col-12 col-lg-6')}
											{this.renderField(this.state.formContent.axt_userprincipalname, 'axt_userprincipalname', 'col-12 col-lg-6', { onBlur: this.onBlurUpn, loading: this.state.searchUpn })}
											{this.renderHabilitarMFA()}
										</div>
									</ContentBox>

									{this.renderPerfisDeAcesso()}
								</div>
							)}
							{timelinePosition === 1 && (
								//Licenciamento e planos
								<div>
									<h2>Dados de acesso</h2>
									<h3>Defina o licenciamento e acesso do usuário</h3>
									<ContentBox className="form-container">
										<div className="row">
											{this.renderField(this.state.formContent.emailVisualization, 'emailVisualization', 'col-12 col-lg-6')}
										</div>
										{this.state.renderizarPlanodeEmail &&
											<div className="row">
												{this.renderListaPacoteCspElicencasOpcionais()}
											</div>
										}
										<div className="row">
											{this.renderValorTotalDoPlano()}
										</div>
									</ContentBox>
								</div>
							)}
							{timelinePosition === 2 && (
								<Confirmation
									state={this.state}
									props={this.props}
									policies={policiesObject}
									checkedPolicy={checkedPolicy}
									setCheckedPolicy={this.setCheckedPolicy}
								/>
							)}
							{timelinePosition > 2 && (
								<Concluded
									renderNewUserPage={this.refreshPage}
								/>
							)}

							{timelinePosition <= 2 && (
								<NavigationButtons
									labelBackButton='Voltar'
									onClickBackButton={this.backPage}
									hideBackButton={timelinePosition == 0}
									labelNextButton={timelinePosition < 2 ? 'Próximo' : 'Confirmar'}
									onClickNextButton={this.nextPage}
									disabledNextButton={
										!this.formularioEhValido() ||
										this.state.searchUpn ||
										this.state.searchEmail ||
										this.state.searchAlternateEmail ||
										this.state.searchBundleLimit ||
										(!this.state.controlAdvisorCod && this.state.form.UsuarioAcessor) ||
										(timelinePosition >= 2 && isTenantNCE() && !checkedPolicy) ||
										(timelinePosition == 1 && this.state.validations._axt_servicebundle_value.hasError)
									}
								/>
							)}
							{this.renderModal()}
							{showLoadingShadow && (this.renderLoadingShadow())}
						</form>
					</ContentWrapperStyle>
				</MasterPage >
			);
		}
	};
}

export default withRouter(connect(
	state => ({
		listaFormFields: state.users.listaFormFields,
		listaPerfis: state.users.listaPerfis,
		listaProdutoComplemento: state.users.listaProdutoComplemento,
		listaProdutoComplementoLoading: state.users.listaProdutoComplementoLoading,
		listaDePacotesCSP: state.users.listaDePacotesCSP,
		listaDePacotesCSPLoading: state.users.listaDePacotesCSPLoading,
		listaPerfisLoading: state.users.listaPerfisLoading,
		listaLinhaServico: state.users.listaLinhaServico,
		listaLinhaServicoLoading: state.users.listaLinhaServicoLoading,
		account: state.users.account
	}),
	(dispatch) => (bindActionCreators({
		setLoadingItem, removeLoadingItem,
		rdxClearUsers, rdxGetUserFields, rdxGetPerfis, rdxGetLinhaServico,
		rdxGetPlansRightsByEntitlementId, rdxGetAdditionalProductsRightByEntitlementId,
		rdxGetAccountById
	}, dispatch))
)(CriarUsuariosV2));
