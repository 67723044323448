import { Button } from "antd";
import { useDrawer } from "context/DrawerContext";
import React from "react"
import GrupoEdicao from "./GrupoEdicao";

function GrupoEdicaoButton({ grupo }){
	const { setup } = useDrawer();
	
	return(
		<Button 
			type="primary" 
			onClick={() => setup(<GrupoEdicao grupoId={grupo.grupoId} />, { title: "Editar grupo do 365", navigationPath:"grupos/editar" })} 
			style={{ marginRight: 8 }} 
		>
			Editar
		</Button>
	)
}
export default GrupoEdicaoButton;