import { Input, Select } from "antd";
import { StyledForm } from "../styles";
import FormInputDivider from "./FormInputDivider";
import { useEffect, useState } from "react";
import { LoadingOutlined, MehOutlined } from '@ant-design/icons';
import { NotificationManager } from 'react-notifications';
import { MessagesDefaultTimeout } from "constants/messagesDefaultTimeout";


export const LoadingType = {
    AoRenderizar: 0,
    AoClicar: 1
}
function PortalSelect({input, divider, form, ...props}){
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [primeiroClick, setPrimeiroClick] = useState(false)

    useEffect(()=>{
        setOptions([])
        if((input.options?.loadingType != null && input.options.loadingType == LoadingType.AoRenderizar) || primeiroClick){
            BuscarOptions()
        }
    }, [input])

    
    const BuscarOptions = async () =>{
        const {callback = null, idField, labelField, defaultValue = null ,autoFillWhenSingleValue = false} = (input?.options || {})
        if(callback == null || typeof callback != "function" || loading == true) return;
        setLoading(true)
        var newOptions = await callback().then(response =>{
            setLoading(false)
            return response.map(data => {
                return {
                    value: data[idField],
                    label: Array.isArray(labelField)
                        ? labelField.map(field => data[field] || "").join(" - ")
                        : data[labelField]
                };
            })
        }).catch(error=>{
            NotificationManager.error(`Não foi possível buscar os dados de '${input.label}'.`, 'Ocorreu um erro!', MessagesDefaultTimeout.Error);
            setLoading(false)
            return [];
        });
        if(autoFillWhenSingleValue && newOptions.length == 1){
            form.setFieldValue([input.name], newOptions[0].value)
            MyOnChange(newOptions[0].value)
        }
        if(defaultValue != null && newOptions.some(x=>x.value == defaultValue)){
            form.setFieldValue([input.name], defaultValue)
            MyOnChange(defaultValue)
        }
        
        setOptions(newOptions)
    }
    const HandleClick = () => {
        if(primeiroClick == false){
            setPrimeiroClick(true);
            if(input.options?.loadingType == null || input.options.loadingType == LoadingType.AoClicar){
                BuscarOptions()
            }
        }
    }

    const RenderizarNotFoundContent = () =>{
        return (
            <div style={{ textAlign: 'center', color: "#46464654"}}>
                {loading?  
                    (
                        <>
                            <LoadingOutlined style={{ fontSize: 20 }}/>
                            <p>Buscando dados...</p>
                        </>
                    )
                    :
                    input?.options?.callback == null?
                    (
                        <>
                            <MehOutlined style={{ fontSize: 20 }} />
                            <p>Preencha os dados necessários antes de realizar esta busca</p>
                        </>
                    )
                    :
                    (
                        <>
                            <MehOutlined style={{ fontSize: 20 }} />
                            <p>Dados não encontrados</p>
                        </>
                    )
                }
            </div>
        )
    }
    const MyOnChange = (newValue) =>{
        if(props.portalOnSelectValueChange != null && typeof props.portalOnSelectValueChange == "function"){
            props.portalOnSelectValueChange(input.name,newValue)
        }
    }
    return (
        <FormInputDivider key={input.name} {...divider}>
            <StyledForm.Item {...input} extra={props.helpMessage}>
                {input.readOnly == null ||  input.readOnly == false ?
                    input.type == "select" ? 
                    <Select
                            options={[...options]}
                            disabled={input?.disabled}
                            onClick={HandleClick}
                            loading={loading}
                            onChange={MyOnChange}
                            notFoundContent={RenderizarNotFoundContent()}
                        />
                    : 
                        <Select
                            showSearch={true}
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={[...options]}
                            disabled={input?.disabled}
                            onClick={HandleClick}
                            loading={loading}
                            onChange={MyOnChange}
                            notFoundContent={RenderizarNotFoundContent()}
                            mode={input?.mode}
                            />      
                :
                    <Input disabled={input?.disabled || input?.readOnly}/>
                }

            </StyledForm.Item>
        </FormInputDivider>
    )
}
export default PortalSelect