import PortalClienteService from "services/bases/PortalClienteService";
import * as HttpRequest from 'EstruturaAntiga/assets/httpRequest';

class GruposService extends PortalClienteService{
    async ListarGrupos(filtro = '', tamanhoPagina = 20, skipToken = '') {
        const userSignature = HttpRequest.userSignature();

        return await this.get(`grupos?tenantCode=${userSignature.tenantCode}&clienteId=${userSignature.accountId}&filtro=${filtro}&tamanhoPagina=${tamanhoPagina}&skipToken=${skipToken}`);
    }
    async ObterGrupoPorId(id, retornarConfiguracoesDeEmail = true, retornarSeEquipeDoTeamsExiste = true, retornarMembros = true) {
        const userSignature = HttpRequest.userSignature();
        const tenantCode = userSignature.tenantCode;

        return await this.get(`grupos/(${id})?tenantCode=${tenantCode}` +
            `&retornarConfiguracoesDeEmail=${retornarConfiguracoesDeEmail}` +
            `&retornarSeEquipeDoTeamsExiste=${retornarSeEquipeDoTeamsExiste}` +
            `&retornarMembros=${retornarMembros}`);
    }
    async CriarGrupo(grupoDTO) {
        return await this.post("grupos/", grupoDTO);
    }
    async ConverterListaDistribuicao(ConversaoListaDistribuicaoDTO) {
        return await this.post("grupos/ConverterListaDistribuicao", ConversaoListaDistribuicaoDTO);
    }
    async EditarGrupo(grupoDTO) {
        return await this.patch("grupos/", grupoDTO);
    }
    async ExcluirGrupo(grupoId) {
        const userSignature = HttpRequest.userSignature();

        const grupoExclusaoDTO = {
            grupoId,
            tenantId: userSignature.axtTenantId,
            direitoId: userSignature.entitlementId
        }
        return await this.delete("grupos/", grupoExclusaoDTO);
    }
}

export default new GruposService();