import { useDrawer } from "context/DrawerContext";
import { Container, StyledA } from "./styles";
import React from "react"
import GrupoCriacao from "./GrupoCriacao";

function GrupoCriacaoButton(props, ref){
	const { setup } = useDrawer();
	return(
		<Container className="nav-item-group">
			<StyledA 
				className={props.tipo == "button" ? "btn btn-default" : "nav-item"} 
				onClick={()=>{ setup(<GrupoCriacao/>,  { title: "Criar novo grupo do 365", navigationPath:"grupos/novo" }) }}
				ref={ref}
			>
				{ props.texto ? props.texto : "Criar Grupo"}
			</StyledA>
		</Container>
	)
}
export default React.forwardRef(GrupoCriacaoButton);