import { Checkbox } from "antd";
import FormInputDivider from "./FormInputDivider";
import { StyledForm } from "../styles";

function PortalCheckboxGroup({ input, divider, ...props }) {
  
  return (
    <FormInputDivider key={input.name} {...divider}>
      <StyledForm.Item {...input} extra={props.helpMessage}>
        <Checkbox.Group
          options={input.options}
          disabled={input?.disabled}
        />
      </StyledForm.Item>
    </FormInputDivider>
  );
}

export default PortalCheckboxGroup;