import { ConfirmFunctionStatus } from "context/DrawerContext";
import React from "react"
import { useEffect, useState } from "react"
import { StyledForm } from "components/Formulario/styles";
import { useImperativeHandle } from 'react';
import { forwardRef } from 'react';
import LoadingOrErrorPanel from "components/LoadingOrErrorPanel";
import Formulario from "components/Formulario/Formulario";
import { Modal } from 'antd';
import { NotificationManager } from "react-notifications";
import { MessagesDefaultTimeout } from "constants/messagesDefaultTimeout";
import GruposService from "services/GruposService";
import UsuariosService from "services/UsuariosService";
import { adalUser } from "EstruturaAntiga/assets/httpRequest";
import { GRUPO_SETTINGS } from "../GrupoSettings";

const GrupoConversao = forwardRef((props, ref) => {
    const [formRef] = StyledForm.useForm();
    const [inputsFormulario, setInputsFormulario] = useState([])
    const [loading, setLoading] = useState(true);
    const [erro, setErro] = useState({temErro: false, mensagem: ''});
    const [formularioErros, setFormularioErros] = useState([]);
    const [valoresIniciais, setValoresIniciais] = useState({});
    const [formularioDesativado, setFormularioDesativado] = useState(false);
    const {setStatusBotaoConfirmar, closeDrawer } = props;

	useEffect(()=>{
        CriarFormulario();
    }, [])

    const confirmFunction = async () => {
        setFormularioErros([]);
        setFormularioDesativado(true);
        setStatusBotaoConfirmar(ConfirmFunctionStatus.Carregando);
        formRef.submit();
    };

    const cancelFunction = () => {}

    useImperativeHandle(ref, () => ({
        confirmFunction,
        cancelFunction
    }));

    const CriarFormulario = async () => {
        const grupo = await ObterListaDistribuicao();
        if (!grupo) {
            setLoading(false);    
            return
        }
        if (grupo.razaoStatus && grupo.razaoStatus == GRUPO_SETTINGS.RAZAO_STATUS.EM_SINCRONIZACAO) {
            closeDrawer();
            modalErro('O grupo já se encontra em sincronização. Aguarde a conclusão da operação para fazer a solicitação.');
            return;
        }

        const tenantCode = JSON.parse(sessionStorage.getItem('signature')).tenantCode;

        const usuarioLogado = adalUser().profile;
        let proprietarioPadrao = null;
        if (tenantCode == usuarioLogado.tid) {
            proprietarioPadrao = {
                key: usuarioLogado.oid,
                nomeParaExibicao: usuarioLogado.name,
                emailPrincipal: usuarioLogado.email
            }
        }

        setValoresIniciais({
            ['nome']: grupo.nomeExibicao,
            ['email']: grupo.email?.split('@')[0],
            ['emailSufixo']: grupo.email?.split('@')[1],
            ['proprietarios']: proprietarioPadrao ? [proprietarioPadrao.key] : [],
            ['permitirRemetentesExternos']: true,
            ['notificarMembrosPorEmail']: true
        });

        GerarInputsFormulario(proprietarioPadrao);

        setLoading(false);
        setStatusBotaoConfirmar(ConfirmFunctionStatus.Ok);
    };

    const ObterListaDistribuicao = async () => {
        let grupo;
        if (!props.grupo.grupoId) {
            setErro({temErro: true, mensagem: 'O Id do grupo não foi informado'});
        }

        await GruposService.ObterGrupoPorId(props.grupo.grupoId, false, false, false)
            .then(response => {
                grupo = response;
            })
            .catch(error => {
                closeDrawer();
                modalErro(error.response?.data?.erros[0] ?? 'Erro inesperado ao obter o grupo');
            });

        return grupo;
    };

	const GerarInputsFormulario = (proprietarioPadrao = null) => {
        const colunasTabelasUsuario = [
            {
              title: 'Nome',
              dataIndex: 'nomeParaExibicao',
              key: 'nomeParaExibicao',
              width: '200px'
            },
            {
              title: 'Email',
              dataIndex: 'emailPrincipal',
              key: 'emailPrincipal',
              width: '200px'
            }
        ];

        let proprietariosDataSource = [];
        if (proprietarioPadrao) {
            proprietariosDataSource.push(proprietarioPadrao);
        }

        let inputsFormulario = [];

        inputsFormulario.push(...[
            { 
                input:{ 
                    name: "nome", 
                    label: "Nome", 
                    type: "text",
                    disabled: true
                }
            },
            { 
                input:{ 
                    name: "email", 
                    label: "Endereço de email do grupo", 
                    type: "textSufixos",
                    disabled: true
                }
            },
            { 
                input:{ 
                    name: "proprietarios", 
                    label: "Proprietários", 
                    rules: [{required: true, message:"Campo obrigatório"}],
                    type: "table"
                },
                dataSource: proprietariosDataSource,
                columns: colunasTabelasUsuario,
                collapse: { open: true },
                adicionarDados: 
                {
                    tituloBotao: "Adicionar Proprietários",
                    callback: async (filtro, tamanhoPagina, skipToken) => { return UsuariosService.ListarUsuariosGrupo(skipToken, filtro, tamanhoPagina) },
                    campoRegistrosDaListagem: 'usuarios',
                    campoIdDaListagem: 'usuarioId',
                    placeholderFiltro: 'Filtrar por nome ou e-mail'
                },
            },
            { 
                input:{ 
                    name: "adicionarEquipeDoTeams",
                    type: "checkbox", 
                    texto: "Adicionar o Microsoft Teams ao seu grupo"
                }
            },
            { 
                input:{ 
                    name: "permitirRemetentesExternos", 
                    type: "checkbox",
                    texto: "Permitir que pessoas de fora da organização enviem e-mails para o grupo"
                }
            },
            { 
                input:{ 
                    name: "notificarMembrosPorEmail", 
                    type: "checkbox",
                    texto: "Enviar uma cópia dos e-mails e eventos para a caixa de entrada dos membros do grupo"
                }
            }
        ]);

        setInputsFormulario(inputsFormulario);
    };

    const handleConfirmacao = async () => {
        const signature = JSON.parse(sessionStorage.getItem('signature'));
        let formValues = formRef.getFieldsValue(true);
        
        const grupo = {
            grupoId: props.grupo.grupoId,
            tenantId: signature.axtTenantId,
            direitoId: signature.entitlementId,
            proprietariosAdicionados: formValues.proprietarios ?? [],
            adicionarEquipeDoTeams: formValues.adicionarEquipeDoTeams ?? false,
            permitirRemetentesExternos: formValues.permitirRemetentesExternos ?? false,
            notificarMembrosPorEmail: formValues.notificarMembrosPorEmail ?? false
        }

        await GruposService.ConverterListaDistribuicao(grupo)
            .then(response => {
                closeDrawer();
                modalSucesso(response);
            })
            .catch(error => {
                setFormularioErros(error.response?.data?.erros ?? ['Erro ao converter grupo']);
                setFormularioDesativado(false);
                setStatusBotaoConfirmar(ConfirmFunctionStatus.Ok);
                modalErro(error.response?.data?.erros[0]);
            })
    };

    const handleFalha = () => {
        NotificationManager.error('Por favor preencha todos os dados corretamente.', 'Formulário incompleto!', MessagesDefaultTimeout.Error);
    };
    
    const handleAlteracaoNosValores = (isSubmitable) => {
        if (isSubmitable || formRef.getFieldValue('proprietarios').length > 0) {
            setStatusBotaoConfirmar(ConfirmFunctionStatus.Ok)
            return;
        }
        setStatusBotaoConfirmar(ConfirmFunctionStatus.Invalido)
    };
    
    const modalSucesso = (mensagem) => {
        Modal.success({
            title: 'Sucesso!',
            content: mensagem,
            centered: true
        });
    };

    const modalErro = (mensagem) => {
        Modal.error({
          title: 'Erro ao converter grupo',
          content: mensagem,
          centered: true
        });
    };

	return(
		<LoadingOrErrorPanel hasError={erro.temErro} loading={loading} mensagemErro={erro.mensagem}>
            <Formulario
                hideSubmitButton={true}
                inputsData={inputsFormulario}
                formReference={formRef}
                onFinish={handleConfirmacao}
                onFinishFailed={handleFalha}
                onSubmitableChange={handleAlteracaoNosValores}
                initialValues={valoresIniciais}
                formErrors={formularioErros}
                disabled={formularioDesativado}
            />
        </LoadingOrErrorPanel>
	)
})

export default GrupoConversao;