import { Input, Select, Space, Form } from "antd";
import FormInputDivider from "./FormInputDivider";
import { StyledForm } from "../styles";

function PortalInputSufixos({input, divider, ...props}){

    const MyOnChange = (newValue) =>{
        if(props.portalOnInputSufixosValueChange != null && typeof props.portalOnInputSufixosValueChange == "function"){
            props.portalOnInputSufixosValueChange(input.name,newValue)
        }
    }

    return(
        <FormInputDivider key={input.name} {...divider}>
            <Space.Compact  block={true}>
                    <StyledForm.Item {...input} extra={props.helpMessage} hidden={input.hidden} style={{width:'100%'}} onBlur={MyOnChange}>
                        <Input disabled={input?.disabled} addonBefore={input?.addonBefore} />
                    </StyledForm.Item>
                    <StyledForm.Item name={input.name + 'Sufixo'} style={{width:'50%', marginTop:'30px'}}>
                        <Select onChange={MyOnChange} disabled={input?.disabled}>
                            {props.selectSufixoOptions}
                        </Select>
                    </StyledForm.Item>
            </Space.Compact>
        </FormInputDivider>
    )
}

export default PortalInputSufixos;
