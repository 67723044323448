import { Button, Table, Collapse, Pagination, Flex, Input, Empty, Modal } from "antd";
import FormInputDivider from "./FormInputDivider";
import { StyledForm } from "../styles";
import { useEffect, useState } from "react";
import LoadingOrErrorPanel from "components/LoadingOrErrorPanel";
import ArrayUtils from "utils/arrays";

function PortalTable({ input, divider, form, ...props }) {
  const [linhasSelecionadas, setLinhasSelecionadas] = useState([]);
  const [novasLinhasSelecionadas, setNovasLinhasSelecionadas] = useState([]);
  const [dadosExistentes, setDadosExistentes] = useState([]);
  const [dadosCarregados, setDadosCarregados] = useState([]);
  const [dadosAdicionados, setDadosAdicionados] = useState([]);
  const [modalAberto, setModalAberto] = useState(false);
  const [paginasArmazenadas, setPaginasArmazenadas] = useState({});
  const [erro, setErro] = useState({temErro: false, mensagem: ''});
  const [loading, setLoading] = useState(false);
  const [filtroAplicado, setFiltroAplicado] = useState('');
  const [inputErro, setInputErro] = useState('');
  const { Search } = Input;
  const [callbackOpcoes, setCallbackOpcoes] = useState({
      total: 10,
      tamanhoPagina: 10,
      paginaAtual: 1,
      skipToken: '',
      filtro: ''
  });

  useEffect(()=>{
    setDadosExistentes(props?.dataSource);
    setLinhasSelecionadas(form.getFieldValue(input.name) ?? []);
  }, [])

  const onChange = () => {
    form.setFieldsValue({ [input.name]: linhasSelecionadas });
  };

  const rowSelectionOnChange = (newSelectedRowKeys) => {
    setLinhasSelecionadas(newSelectedRowKeys);
    form.setFieldsValue({ [input.name]: newSelectedRowKeys });
  };

  const rowSelection = {
    selectedRowKeys: linhasSelecionadas,
    preserveSelectedRowKeys: true,
    hideSelectAll: true,
    onChange: rowSelectionOnChange
  };

  const adicionarDadosRowSelectionOnChange = (newSelectedRowKeys) => {
    setNovasLinhasSelecionadas(newSelectedRowKeys);
  };

  const adicionarDadosRowSelectionOnSelect = (record, selected) => {
    if (selected) {
      setDadosAdicionados(prevDadosAdicionados => [...prevDadosAdicionados, record]);
    } else {
      setDadosAdicionados(prevDadosAdicionados => prevDadosAdicionados.filter(dado => dado !== record));
    }
  };

  const adicionarDadosRowSelectionOnSelectAll = (selected, selectedRows, changeRows) => {
    if (selected) {
      setDadosAdicionados(prevDadosAdicionados => [...prevDadosAdicionados, ...changeRows]);
    } else {
      setDadosAdicionados(prevDadosAdicionados => prevDadosAdicionados.filter(dado => !changeRows.includes(dado)));
    }
  };

  const rowSelectionAdicionarDados = {
    selectedRowKeys: novasLinhasSelecionadas,
    preserveSelectedRowKeys: true,
    onChange: adicionarDadosRowSelectionOnChange,
    onSelect: adicionarDadosRowSelectionOnSelect,
    onSelectAll: adicionarDadosRowSelectionOnSelectAll
  };

  const MapearRegistrosParaDataSource = (registros, campoIdDaListagem) => {
    return registros.map(obj => {
      const entries = Object.entries(obj);
      const [, keyValue] = entries.find(([key]) => key === campoIdDaListagem);
      const resto = Object.fromEntries(entries.filter(([key]) => key !== campoIdDaListagem));

      return { key: keyValue, ...resto };
    });
  }

  const BuscarDadosASeremAdicionados = async (pagina = 1, tamanhoPagina = 10, filtro = '', skipToken = '') =>{
    setLoading(true);

    const {callback = null, campoRegistrosDaListagem, campoIdDaListagem} = (props?.adicionarDados || {})
    if(callback == null || typeof callback != "function" || loading == true) return;

    const dados = await callback(filtro, tamanhoPagina, skipToken)
          .catch(error =>{
            console.log(error.response?.data?.erros[0])
            setErro({temErro: true, mensagem: error.response?.data?.erros[0] ?? 'Erro inesperado ao obter os dados'});
            setLoading(false);
            return;
        });

    if(dados == undefined) return;

    const registros = dados[campoRegistrosDaListagem];

    setCallbackOpcoes((prevCallbackOpcoes) =>({
        ...prevCallbackOpcoes,
        total: pagina == 1 ? dados.quantidadeRegistros : prevCallbackOpcoes.total,
        skipToken: dados.skipToken == null ? '' : dados.skipToken
    }));

    const dataSource = MapearRegistrosParaDataSource(registros, campoIdDaListagem);
    setDadosCarregados(dataSource);
    setPaginasArmazenadas((prevPaginasArmazenadas) => ({
        ...prevPaginasArmazenadas,
        [pagina]: [...dataSource],
    }));

    setLoading(false);
  }

  const handleAlteracaoDePaginacao = (paginaAtual, tamanhoPagina) =>{
    let tamanhoPaginaAtual = callbackOpcoes.tamanhoPagina;
    let skipToken = callbackOpcoes.skipToken;

    if (tamanhoPaginaAtual != tamanhoPagina) {
        skipToken = '';
        paginaAtual = 1;
        setPaginasArmazenadas({});
    }

    setCallbackOpcoes({
        ...callbackOpcoes,
        paginaAtual,
        tamanhoPagina,
        skipToken
    })

    if(paginasArmazenadas[paginaAtual] && tamanhoPaginaAtual == tamanhoPagina){
      setDadosCarregados(paginasArmazenadas[paginaAtual]);
    } else {
      BuscarDadosASeremAdicionados(paginaAtual, tamanhoPagina, filtroAplicado, skipToken);
    }
  }

  const abrirModal = async () => {
    setModalAberto(true);
    handleAlteracaoDePaginacao(1, callbackOpcoes.tamanhoPagina);
    setNovasLinhasSelecionadas(linhasSelecionadas);
  };

  const handleConfirmacaoModal = () => {
    if (dadosAdicionados.length > 0) {
      setDadosExistentes(prevDadosExistentes => [...prevDadosExistentes, ...ArrayUtils.getAddedItems(prevDadosExistentes, dadosAdicionados, 'key')]);
      setDadosAdicionados([]);
    }
    if (filtroAplicado) {
      setFiltroAplicado('');
      setPaginasArmazenadas({});
    }
    if (erro.temErro) {
      setErro({temErro: false, mensagem: ''});
    }

    setLinhasSelecionadas(novasLinhasSelecionadas);
    form.setFieldsValue({ [input.name]: novasLinhasSelecionadas });
    setModalAberto(false);
  };

  const handleCancelarModal = () => {
    if (filtroAplicado) {
      setFiltroAplicado('');
      setPaginasArmazenadas({});
    }
    if (erro.temErro) {
      setErro({temErro: false, mensagem: ''});
    }

    setModalAberto(false);
  };

  const handleFiltrar = async (valorFiltro) => {
    setPaginasArmazenadas({});
    setFiltroAplicado(valorFiltro);
    setCallbackOpcoes({
        ...callbackOpcoes,
        paginaAtual: 1
    });

    await BuscarDadosASeremAdicionados(1, callbackOpcoes.tamanhoPagina, valorFiltro);
  }

  const body = () => {
    return (
      <StyledForm.Item {...input} extra={props.helpMessage} label={props.collapse ? "" : input.label}
        rules={[...(input?.rules || []),
          ({isFieldTouched}) =>({
            validator(_, value){
              if (!isFieldTouched(input.name)) return Promise.resolve();
              if(input?.rules?.some(rule => rule.required)){
                if(linhasSelecionadas.length == 0){
                  setInputErro(input?.rules?.message ?? "Campo obrigatório");
                  return Promise.reject(input?.rules?.message ?? "Campo obrigatório")
                }
              }
              setInputErro('');
              return Promise.resolve();
            }
          })
        ]}
      >
      {
      props.adicionarDados ? 
      <Button onClick={abrirModal} type="primary" style={{marginBottom: '16px'}}>{props.adicionarDados?.tituloBotao ?? "Adicionar"}</Button>
      : ""
      }
      <Table
        dataSource={dadosExistentes}
        columns={props?.columns}
        rowSelection={rowSelection}
        onChange={onChange}
        pagination={{showTotal:(total) => `Selecionados: ${linhasSelecionadas.length} Total: ${total}` }}
        tableLayout="auto"
        size="small"
        scroll= { dadosExistentes.length > 0 ? { y: 275 } : undefined }
        locale={{ emptyText: <Empty description={'Sem dados'} image={Empty.PRESENTED_IMAGE_SIMPLE}/> }}
      />
      {props.adicionarDados ?
        <Modal title={props.adicionarDados?.tituloBotao ?? "Adicionar"} open={modalAberto} onOk={handleConfirmacaoModal} onCancel={handleCancelarModal} width={800}>
          <LoadingOrErrorPanel loading={loading} hasError={erro.temErro} mensagemErro={erro.mensagem}>
            <Flex justify='flex-end' >
              <Search
                placeholder={props.adicionarDados?.placeholderFiltro ?? "Filtrar dados"}
                onSearch={handleFiltrar}
                allowClear
                style={{width: 235}}
              />
            </Flex>
            <Table 
              dataSource={dadosCarregados}
              columns={props?.columns}
              rowSelection={rowSelectionAdicionarDados} 
              pagination={false}
              tableLayout="auto"
              size="small"
              scroll= { dadosCarregados.length > 0 ? { y: 275 } : undefined }
              locale={{ emptyText: <Empty description={'Sem dados'} image={Empty.PRESENTED_IMAGE_SIMPLE}/> }}
            />
            <Pagination 
                  defaultCurrent={1}
                  simple={{ readOnly: true }}
                  total={callbackOpcoes.total}
                  showTotal={(total) => `Selecionados: ${novasLinhasSelecionadas.length} Total: ${total}`}
                  pageSize={callbackOpcoes.tamanhoPagina}
                  onChange={handleAlteracaoDePaginacao}
                  disabled={loading}
                  showSizeChanger={callbackOpcoes.total > 10}
                  current={callbackOpcoes.paginaAtual}
                  locale={{ items_per_page: '/ página' }}
            />
          </LoadingOrErrorPanel>
        </Modal>
        : ""
      }
    </StyledForm.Item>
    );
  }

  const spanDeCampoObrigatorio = () =>{
    if (input?.rules?.some(rule => rule.required)) {
      return <span style={{color: '#ff4d4f', fontFamily:'SimSun,sans-serif', marginInlineEnd:'4px'}}>*</span>;
    }
    return '';
  }

  const items = [
    {
      key: 1,
      label: <>{spanDeCampoObrigatorio()}{input.label}</>,
      children: body()
    }
  ];

  const styleCollapse = () => {
    let style = {}
    if (inputErro) {
      style.borderBottom = '1px solid';
      style.borderColor = '#ff4d4f';
    }
    return style;
  }

  return (
    <FormInputDivider key={input.name} {...divider}>
      {props.collapse ? 
      <div style={{marginBottom:'8px'}}>
        <Collapse items={items} defaultActiveKey={[props.collapse?.open ? 1 : 0]} style={styleCollapse()} />
        {inputErro ? <span style={{color: '#ff4d4f'}}>{inputErro}</span> : ""}
      </div>
      :
      <>
        {body()}
        {inputErro ? <span style={{color: '#ff4d4f'}}>{inputErro}</span> : ""}
      </>
    }
    </FormInputDivider>
  );
}

export default PortalTable;