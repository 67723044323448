import ObjectUtils from "./objects";

const ArrayUtils = {
  /**
   * Recebe um array de objetos e o nome de um campo e aplica um "unique" no array baseado no valor do campo.
   * 
   * Por exemplo, usando o array com o campo "nome":
   *  [
   *    {nome: "joao", idade:20},
   *    {nome: "pedro", idade:20},
   *    {nome: "joao", idade:45}
   *  ]
   * 
   * Irá retornar:
   *  [
   *    {nome: "joao", idade:20},
   *    {nome: "pedro", idade:20}
   *  ]
   * 
   * @param {Array.<object>} arr 
   * @param {string} field Nome do campo a ser aplicado o "unique"
   * @returns {Array.<object>}
   */

  uniqueByField(arr, field) {
    return arr.filter((item, index, self) => {
      return index === self.findIndex(t => t[field] === item[field]);
    });
  },
  /**
   * Recebe um array de objetos, e retorna um array de T, sendo T o tipo do campo solicitado como "field". Além disso executa um "unique" no retorno.
   * @param {Array.<object>} arr 
   * @param {*} field 
   * @returns {Array.<typeof field>}
   */
  uniqueFieldValues(arr, field) {
    const uniqueValues = new Set();
    return arr.reduce((result, obj) => {
      if (!uniqueValues.has(obj[field])) {
        uniqueValues.add(obj[field]);
        result.push(obj[field]);
      }
      return result;
    }, []);
  },
  /**
   * Recebe dois arrays de tipo primitivo, e retorna um array do mesmo tipo, com somente os itens adicionados no segundo.
   * @param {Array.<*>} array1 
   * @param {Array.<*>} array2 
   * @returns {Array.<*>}
   */
  getAddedItems(array1, array2, keyField = null) {
    let arrayFinal = array2.filter(item2 => {
      if (keyField) {
        return !array1.some(item1 => ObjectUtils.objetoInvalido(item1) || ObjectUtils.objetoInvalido(item2) || item1[keyField] === item2[keyField]);
      } else {
        return !array1.includes(item2);
      }
    });
    return arrayFinal;
  },
  /**
   * Recebe dois arrays de tipo primitivo, e retorna um array do mesmo tipo, com somente os itens removidos no segundo.
   * @param {Array.<*>} array1 
   * @param {Array.<*>} array2 
   * @returns {Array.<*>}
   */
  getRemovedItems(array1, array2) {
    return array1.filter(item => !array2.includes(item));
  },
  /**
   * Recebe dois arrays de tipo primitivo, e retorna true caso ambos sejam iguais, mesmo que os seus valores estejam em ordens diferentes.
   * @param {Array.<*>} array1 
   * @param {Array.<*>} array2 
   * @returns {boolean}
   */
  arraysAreEqual(array1, array2) {
    if (array1.length !== array2.length) {
        return false;
    }

    const sortedArray1 = [...array1].sort();
    const sortedArray2 = [...array2].sort();

    return sortedArray1.every((value, index) => value === sortedArray2[index]);
  }
}

export default ArrayUtils