import { useEffect, useState } from "react"
import { withRouter  } from 'react-router-dom'
import { NotificationManager } from 'react-notifications';
import Formulario from "components/Formulario/Formulario";
import { StyledForm } from "components/Formulario/styles";
import { MessagesDefaultTimeout } from "constants/messagesDefaultTimeout";
import PesquisasService from "services/PesquisasService";
import Logo from 'assets/images/logo.png';
import './style.scss';
import queryString from 'query-string'
import LoadingOrErrorPanel from "components/LoadingOrErrorPanel";

import { Row, Col} from "antd";

function PesquisaSatisfacao({ history, ...props}){
    const [loading, setLoading] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [mensagemErro, setMensagemErro] = useState('');
    const [inputsFormulario, setInputsFormulario] = useState([]);
    const [form] = StyledForm.useForm();
    const [perguntas, setPerguntas] = useState({});
    const [ocorrencia, setOcorrencia] = useState({});
    const [dataInicio, setDataInicio] = useState(new Date());
    const [identificadorPesquisa, setIdentificadorPesquisa] = useState('');
    const [parametros, setParametros] = useState({});
    const [hiddenAutorizacaoPublicarResposta, setHiddenAutorizacaoPublicarResposta] = useState(true);

    useEffect(()=>{
        const {ocorrenciaId, nota} = queryString.parse(props.location.search);
        if (!ocorrenciaId){
            setMensagemErro('Id da ocorrência não informado.');
            setHasError(true);
            setLoading(false);
            return;
        }

        CriarFormulario(ocorrenciaId);
        setParametros({ocorrenciaId, nota});
    },[]);

    const CriarFormulario = async (ocorrenciaId) => {
        await PesquisasService.ObterPesquisaSatisfacaoOcorrencia(ocorrenciaId)
            .then(response => {
                setIdentificadorPesquisa(response.identificador);
                setOcorrencia(response.ocorrencia);
                
                var inputs = GerarInputsPadrao(response.perguntas)

                setInputsFormulario(inputs);
                setDataInicio(new Date());
                setLoading(false);
            })
            .catch(error => {
                setMensagemErro(error.response?.data?.erros[0] ?? 'Erro ao obter a pesquisa de satisfação');
                setHasError(true)
                setLoading(false)
            })
    }
    
    const GerarInputsPadrao = (perguntasPesquisa) => {
        let nps = perguntasPesquisa.find(x => x.tipoVisual == "nps");
        let opcoes = perguntasPesquisa.find(x => x.tipoVisual == "opcao");
        let comentario = perguntasPesquisa.find(x => x.tipoVisual == "texto");
        let autorizacaoPublicarResposta = perguntasPesquisa.find(x => x.tipoVisual == "checkbox");
        let codigoOcorrencia = perguntasPesquisa.find(x => x.tipoVisual == "codigo");
        let ocorrenciaId = perguntasPesquisa.find(x => x.tipoVisual == "id");
        setPerguntas({nps, opcoes, comentario, autorizacaoPublicarResposta, codigoOcorrencia, ocorrenciaId});

        let retorno = [];
        retorno.push(...[
            { 
                input:{ 
                    name: nps.identificador,
                    label: nps.pergunta,
                    type: "radio",
                    options: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
                    rules: [{required: nps.obrigatorio, message:"Campo obrigatório"}],
                    size: "large"
                },
                style: {align:"center"}
            },
            { 
                input:{ 
                    name: opcoes.identificador,
                    label: opcoes.pergunta,
                    type: "radio",
                    options: opcoes.opcoes,
                    rules: [{required: opcoes.obrigatorio, message:"Campo obrigatório"}],
                },
                style: {align:"center", optionType:"button", buttonStyle:"solid"}
            },
            { 
                input:{ 
                    name: comentario.identificador, 
                    label: comentario.pergunta, 
                    type: "textarea",
                    rules: [{required: comentario.obrigatorio, message:"Campo obrigatório"}],
                }
            },
            { 
                input:{ 
                    name: autorizacaoPublicarResposta.identificador, 
                    type: "checkboxGroup", 
                    options: [{label: autorizacaoPublicarResposta.pergunta, value: "Sim"}], 
                    rules: [{required: autorizacaoPublicarResposta.obrigatorio, message:"Campo obrigatório"}],
                    hidden: true
                }
            }
        ]);
        
        return retorno;
    }
    
    const onFinish = async (values) => {
        setDisabled(true)
        setLoading(true)

        values[perguntas.codigoOcorrencia.identificador] = ocorrencia.numeroOcorrencia;
        values[perguntas.ocorrenciaId.identificador] = ocorrencia.ocorrenciaId;

        let respostas = Object.keys(values)
            .filter(chave => values[chave] !== undefined)
            .map(chave => ({
            identificador: chave,
            resposta: Array.isArray(values[chave]) ? values[chave][0] : values[chave]
          }));

        SubstituirRespostaAutorizacaoPublicarRespostaPorNaoCasoCampoOcultoOuSemValor(respostas);

        respostas = respostas.filter(r => r.resposta); 

        let dados = {
            identificador: identificadorPesquisa,
            codigoOcorrencia: ocorrencia.numeroOcorrencia,
            contatoId: ocorrencia.solicitanteId,
            contato: ocorrencia.nomeCompletoSolicitante,
            contatoEmail: ocorrencia.emailSolicitante,
            dataInicio,
            dataEnvio: new Date(),
            respostas
        }

        await PesquisasService.CriarRepostaPesquisaSatisfacao(dados)
            .then(() => {              
                history.push(`/pesquisa-satisfacao-conclusao`)
            })
            .catch(error => {
                NotificationManager.error(error.response?.data?.erros[0] ?? 'Ocorreu um erro ao registrar seu feedback.', '', MessagesDefaultTimeout.Error);
                setDisabled(false)
                setLoading(false)
            })
    };

    function SubstituirRespostaAutorizacaoPublicarRespostaPorNaoCasoCampoOcultoOuSemValor(respostas) {
        let respostaAutorizacaoPublicarResposta = respostas.find(resposta => resposta.identificador == perguntas.autorizacaoPublicarResposta.identificador);
        if (respostaAutorizacaoPublicarResposta && (hiddenAutorizacaoPublicarResposta || !respostaAutorizacaoPublicarResposta.resposta)) {
            let respostaAutorizacaoPublicarRespostaIndex = respostas.findIndex(x => x.identificador == respostaAutorizacaoPublicarResposta.identificador);
            respostas[respostaAutorizacaoPublicarRespostaIndex].resposta = "Não";
        }
    }

    const onValuesChange = (changedValues) => {
        if (perguntas.nps.identificador in changedValues || perguntas.comentario.identificador in changedValues) {
            if (form.getFieldValue(perguntas.nps.identificador) >= 9 && form.getFieldValue(perguntas.comentario.identificador)) {
                ocultarAutorizacaoPublicarResposta(false);
                setHiddenAutorizacaoPublicarResposta(false);
            }
            else {
                ocultarAutorizacaoPublicarResposta(true);
                setHiddenAutorizacaoPublicarResposta(true);
            }
        }
    };

    const ocultarAutorizacaoPublicarResposta = (hiddenAutorizacaoPublicarResposta) => {
        let newInputs = [...inputsFormulario];
        let autorizacaoPublicarRespostaIndex = newInputs.findIndex(x => x.input.name == perguntas.autorizacaoPublicarResposta.identificador);
        let autorizacaoPublicarRespostaInput = newInputs[autorizacaoPublicarRespostaIndex];
        newInputs[autorizacaoPublicarRespostaIndex] = {
            ...autorizacaoPublicarRespostaInput,
            input:{
                ...autorizacaoPublicarRespostaInput.input,
                hidden: hiddenAutorizacaoPublicarResposta
            }
        }
        setInputsFormulario(newInputs);
    };

    return(
        <Row justify={"center"} className="pesquisa-satisfacao">
            <Col xs={{ flex: '90%' }}
                sm={{ flex: '80%' }}
                md={{ flex: '70%' }}
                lg={{ flex: '70%' }}
                xl={{ flex: '70%' }}
            >
                <LoadingOrErrorPanel hasError={hasError} loading={loading} mensagemErro={mensagemErro}>
                    <img src={Logo} alt="BHS" />
                    <br/>
                    <h2>Olá, {ocorrencia.nomeSolicitante ?? "cliente"}!</h2>
                    <h2>A sua solicitação foi concluída.</h2>
                    <br/>
                    <h3>Obrigado por utilizar nosso suporte! Para melhorarmos continuamente, gostaríamos de saber como foi sua experiência. Seu feedback é essencial e a pesquisa levará apenas alguns minutos.</h3>
                    <br/>
                    <Formulario 
                        onFinish={onFinish}
                        inputsData={inputsFormulario} 
                        disabled={disabled}
                        formReference={form}
                        initialValues={{[perguntas?.nps?.identificador]: parametros.nota}}
                        onValuesChange={onValuesChange}
                        submitButtonAlign="center"
                        inputsSpace={{direction: "vertical", inputSpaceSize: "large"}}
                    />
                </LoadingOrErrorPanel>
            </Col>
        </Row>
    )
}

export default withRouter(PesquisaSatisfacao)