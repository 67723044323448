import { Descriptions, Flex, Button } from 'antd';
import { StyledCard } from './styles';
import { withRouter } from 'react-router-dom';
import { useEffect, useState } from 'react';
import GrupoExclusaoButton from 'pages/Grupos/Exclusao/GrupoExclusaoButton';
import GrupoEdicaoButton from 'pages/Grupos/Edicao/GrupoEdicaoButton';
import GrupoConversaoButton from 'pages/Grupos/Conversao/GrupoConversaoButton';
import { GRUPO_SETTINGS } from 'pages/Grupos/GrupoSettings';

function GrupoCard({ grupo }){
	const [itensDescricao, setItensDescricao] = useState([]);
	
	useEffect(()=>{
		let newItems = [
			{ children: DadosGrupo() },
			{ children: Botoes(), contentStyle: {justifyContent: 'flex-end' }}
		];
		setItensDescricao(newItems);
	}, [grupo]);

	const DadosGrupo = () => {
		return (
			<Flex vertical justify='flex-start' >
				<span style={{marginBottom: '5px'}}> {grupo.nomeExibicao} </span>
				<span> Email: {grupo.email}  </span>
			</Flex>
		)
	}
	
	const Botoes = () =>{
		let ehListaDistribuicao = grupo.tipoGrupo == GRUPO_SETTINGS.TIPOS_GRUPO.LISTA_DISTRIBUICAO;
		
		return (
			<Flex vertical justify='flex-end' align='center'>
				{
					ehListaDistribuicao ?
						<GrupoConversaoButton grupo={grupo}/>
					: 
					<div>
						<GrupoEdicaoButton grupo={grupo}/>
						<GrupoExclusaoButton grupoId={grupo.grupoId}/>
					</div>
				}
			</Flex>
		)
	}

	return(
		<StyledCard bordered={true}>
			<Descriptions items={itensDescricao} bordered={false} size="middle" column={2} />
		</StyledCard>
	)
}

export default withRouter(GrupoCard)