import GrupoEmail from '../../../../assets/icons/grupo_email.svg';
import Usuarios from '../../../../assets/icons/usuarios.svg';
import Licensas from '../../../../assets/icons/licencas.svg';
import Atendimentos from '../../../../assets/icons/atendimento.svg';
import Suporte from '../../../../assets/icons/suporte.svg';
import M365 from '../../../../assets/icons/ouro.svg';
import Nuvem from '../../../../assets/icons/nuvem.svg';
import OnPremise from '../../../../assets/icons/onPremise.svg';
import Compliance from '../../../../assets/icons/compliance.svg';
import Dispositivos from '../../../../assets/icons/dispositivos.svg';
import SuportePremium from '../../../../assets/icons/suporte_premium.svg';
import Outsourcing from '../../../../assets/icons/outsourcing.svg';
import Servicos from '../../../../assets/icons/Solicitacao-De-Servico.svg';
import Financeiro from '../../../../assets/icons/Financeiro.svg';
import DownloadBoleto from '../../../../assets/icons/DownloadBoleto.svg';
import DownloadExtrato from '../../../../assets/icons/DownloadExtrato.svg';
import DownloadRps from '../../../../assets/icons/DownloadRps.svg';
import AtualizarBoleto from '../../../../assets/icons/AtualizarBoleto.svg';
import Grupos from '../../../../assets/icons/Grupos.svg';

export default function returnIcons (iconName) {
  if (iconName === 'icon-grupo-email') return GrupoEmail
  else if (iconName === 'icon-licensas') return Licensas
  else if (iconName === 'icon-user') return Usuarios
  else if (iconName === 'icon-atendimentos') return Atendimentos
  else if (iconName === 'icon-preciso-suporte') return Suporte
  else if (iconName === 'icon-m365') return M365
  else if (iconName === 'icon-nuvem') return Nuvem
  else if (iconName === 'icon-compliance') return Compliance
  else if (iconName === 'icon-onpremise') return OnPremise
  else if (iconName === 'icon-dispositivos') return Dispositivos
  else if (iconName === 'icon-outsourcing') return Outsourcing
  else if (iconName === 'icon-suporte-premium') return SuportePremium
  else if (iconName === 'icon-preciso-servico') return Servicos
  else if (iconName === 'icon-suporte') return Suporte  
  else if (iconName === 'icon-financeiro') return Financeiro  
  else if (iconName === 'icon-download-boleto') return DownloadBoleto
  else if (iconName === 'icon-download-extrato') return DownloadExtrato
  else if (iconName === 'icon-download-rps') return DownloadRps
  else if (iconName === 'icon-atualizar-boleto') return AtualizarBoleto
  else if (iconName === 'icon-grupo') return Grupos
  else ''
}
