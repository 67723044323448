import { Checkbox } from "antd";
import FormInputDivider from "./FormInputDivider";
import { StyledForm } from "../styles";

function PortalCheckbox({ input, divider, ...props }) {
  
  return (
    <FormInputDivider key={input.name} {...divider}>
      <StyledForm.Item {...input} extra={props.helpMessage} valuePropName="checked">
        <Checkbox onChange={input?.onChange} disabled={input?.disabled}>{input?.texto}</Checkbox>
      </StyledForm.Item>
    </FormInputDivider>
  );
}

export default PortalCheckbox;