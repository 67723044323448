import PortalClienteService from "services/bases/PortalClienteService";
import * as HttpRequest from 'EstruturaAntiga/assets/httpRequest';

class DominiosService extends PortalClienteService{
    async ListarSufixosPorCliente() {
        const userSignature = HttpRequest.userSignature();

        return await this.get(`dominios/ListarSufixosPorCliente?tenantCode=${userSignature.tenantCode}&clienteId=${userSignature.accountId}`);
    }
}

export default new DominiosService();