import { Button, Modal } from 'antd';
import GruposService from 'services/GruposService';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { GRUPO_SETTINGS } from '../GrupoSettings';

function GrupoExclusaoButton({ grupoId }){

    const handleExclusao = async () => {
        await GruposService.ExcluirGrupo(grupoId)
            .then(response => {
                modalSucesso(response);
            })
            .catch(error => {
                modalErro(error.response?.data?.erros[0] ?? ['Erro inesperado ao excluir grupo']);
            })
    };

    const ObterGrupo = async () => {
        let grupo;
        if (!grupoId) {
            modalErro('O Id do grupo não foi informado');
        }

        await GruposService.ObterGrupoPorId(grupoId, false, false, false)
            .then(response => {
                grupo = response;
            })
            .catch(error => {
                modalErro(error.response?.data?.erros[0] ?? 'Erro inesperado ao obter o grupo');
            });

        return grupo;
    }

    const modalConfirmacao =  async () => {
        const grupo = await ObterGrupo();
        if (!grupo) {
            return
        }
        if (grupo.razaoStatus && grupo.razaoStatus == GRUPO_SETTINGS.RAZAO_STATUS.EM_SINCRONIZACAO) {
            modalErro('O grupo já se encontra em sincronização. Aguarde a conclusão da operação para fazer a solicitação.');
            return;
        }
        
        Modal.confirm({
            title: 'Tem certeza que deseja excluir o grupo abaixo?',
            icon: <ExclamationCircleFilled />,
            content: bodyConfirmacao(grupo),
            okText: 'Confirmar',
            okType: 'danger',
            cancelText: 'Cancelar',
            centered: true,
            async onOk() {
                await handleExclusao();
            }
        });
    };

    const modalSucesso = (mensagem) => {
        Modal.success({
            title: 'Sucesso!',
            content: mensagem,
            centered: true
        });
    };

    const modalErro = (mensagem) => {
        Modal.error({
          title: 'Erro ao excluir grupo',
          content: mensagem,
          centered: true
        });
    };

    const bodyConfirmacao = (grupo) => {
        return (
            <>
                <p style={{marginBottom: '0'}}>Nome: {grupo.nomeExibicao}</p>
                <p>Email: {grupo.email}</p>
            </>
        )
    };

    return(
        <Button type='primary' onClick={()=> modalConfirmacao()} danger >
            Excluir
        </Button>
    )
}

export default GrupoExclusaoButton;