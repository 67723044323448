export const GRUPO_SETTINGS = Object.freeze({
    RAZAO_STATUS: Object.freeze({
        ATIVO: 1,
        INATIVO: 2,
        EM_SINCRONIZACAO: 321770001
    }),
    TIPOS_GRUPO: Object.freeze({
		GRUPO_365: 1,
		LISTA_DISTRIBUICAO: 2
    })
});
