import { Button, Tooltip } from "antd";
import { useDrawer } from "context/DrawerContext";
import React from "react"
import GrupoConversao from "./GrupoConversao";

function GrupoConversaoButton({ grupo }){
	const { setup } = useDrawer();

	const tooltip = <p>Gerencie os membros da lista no novo Outlook, Outlook Web ou converta-a em um grupo do Microsoft 365 para usar o Portal do Cliente. Saiba mais: <a href='https://support.microsoft.com/pt-br/topic/grupos-de-distribui%C3%A7%C3%A3o-e8ba58a8-fab2-4aaf-8aa1-2a304052d2de#bkmk_create'>Grupos de Distribuição</a>.</p>;
	
	return(
		<Tooltip title={tooltip}>
		<Button 
			type="primary" 
			onClick={() => setup(<GrupoConversao grupo={grupo} />, { title: "Converter lista de distribuição para grupo do Microsoft 365", navigationPath:"grupos/converter" })} 
			style={{ marginRight: 8 }} 
		>
			Converter para grupo do Microsoft 365
		</Button>
		</Tooltip>
	)
}
export default GrupoConversaoButton;